import Hero from '../../Components/Hero/Hero';
import Calendar from './Components/Calendar/Calendar';
import { calendar } from '../../Globals/calendar';
import CurrentRace from './Components/CurrentRace/CurrentRace';
import ErrorBoundary from '../../Helpers/errorBoundary';
import LatestNews from '../../Components/Homepage/LatestNews/LatestNews';
import ImageGallery from './Components/ImageGallery/ImageGallery';
import { heroDataSlider } from '../../Components/Hero/sliderData';

const Races = () => {
  // Current race
  const currentRace = calendar.filter((race) => race.active);

  return (
    <section id="races">
      <Hero props={heroDataSlider} banner={true} />
      <Calendar calendar={calendar} />
      <ErrorBoundary fallback="Current race in progress ...">
        <CurrentRace {...currentRace} />
      </ErrorBoundary>
      <LatestNews />
      <ImageGallery />
    </section>
  );
};
export default Races;
