import React from 'react';
import { IDescription } from '../../Globals/interfaces';

const Description: React.FC<IDescription> = ({ ...props }): JSX.Element => {
  return (
    <div
      className="font-size-18 fonr-ti-r light-gray-color text-start lh-13"
      style={{ maxWidth: '650px' }}
    >
      {props.text}
    </div>
  );
};

export default Description;
