import React from 'react';
import Hero from '../../Components/Hero/Hero';
import AboutRacing from '../../Components/Homepage/AboutRacing/AboutRacing';
import LatestNews from '../../Components/Homepage/LatestNews/LatestNews';
import MeetTheDrivers from '../../Components/Homepage/MeetTheDrivers/MeetTheDrivers';
import Podcast from '../../Components/Homepage/Podcast/Podcast';
import OurPartners from '../../Components/Homepage/OurPartners/OurPartners';

import { heroDataSlider } from '../../Components/Hero/sliderData';
import LatestPodcast from '../News/components/LatestPodcast/LatestNews/LatestPodcast';

const Homepage: React.FC = (): JSX.Element => {
  return (
    <>
      {/* <Helmet>
        <title>RNF | Partners</title>
      </Helmet> */}

      <section id="partners">
        <Hero props={heroDataSlider} banner={true} />
        <AboutRacing />
        <LatestNews />
        <MeetTheDrivers />
        {/* <TheBike /> */}
        <Podcast />
        <LatestPodcast/>
        <OurPartners />
      </section>
    </>
  );
};

export default Homepage;
