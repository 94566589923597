import { data } from '../../../../Globals/globalVariables';
import { ICalendarProps } from '../Calendar/CalendarItem';
import RaceColumn from './RaceColumn';
import RaceHeader from './RaceHeader';

import driver1 from '../../assets/race_1.png';
import driver2 from '../../assets/race_2.png';

import qualifier1 from '../../assets/q1.png';

import './CurrentRace.scss';
import RaceFeatures from './RaceFeatures';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const CurrentRace = ({ ...race }: ICalendarProps[]) => {
  const { management } = data;

  const [res, setRes] = useState({
    TotalLength: '0',
    TrackWidth: '0',
    LeftCorners: '0',
    RightCorners: '0',
    LongestStraight: '0',
    NextRace:""
  });

  // Throw error if multiple races are active
  if (Object.keys(race).length > 1) {
    throw new Error('No active races');
  }

  // Get only drivers from management
  const availableRacers = {
    ...management.filter((team) => team.position === 'driver')
  };
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_RNF_SERVER + '/api/race'
        );

        if (response.status === 200) {
          setRes(
            response.data.data.attributes
          );
 
        }
      } catch (err) {
        // setSliderData(props.props);
        toast.info('Error in loading hero slider data', {
          toastId: 'error-hero-slider'
        });
      }
    };

    fetchData();
  }, []);
  return (
    <div
      className="current-race container-main"
      onClick={() => console.log(res)}
    >
      <div className="row">
        <div className="col-md-4 col-12 order-md-0 order-0">
          <RaceColumn
            head={
              <RaceHeader
                // position={qualifier1}
                title={availableRacers[0].name}
                image={driver1}
              />
            }
            // features={<RaceFeatures />}
          />
        </div>
        <div className="col-md-4 col-12 order-md-1 order-2">
          <RaceColumn
            head={
              <RaceHeader
                type="circuit"
                subtitle={race[0].date.fullDate}
                circuit={res.NextRace}
                title={res.NextRace}
                image={race[0].event.image}
              />
            }
          />
          
          {[
            {
              name: 'Total length',
              value: res?.TotalLength
            },
            {
              name: 'Track width',
              value: res?.TrackWidth
            },
            {
              name: 'Left corners',
              value: res?.LeftCorners
            },
            {
              name: 'Right corners',
              value: res?.RightCorners
            },
            {
              name: 'Longest straight',
              value: res?.LongestStraight
            }
          ].map((el) => (
            <RaceFeatures feature={el} />
          ))}
        </div>
        <div className="col-md-4 col-12  order-md-2 order-1">
          <RaceColumn
            head={
              <RaceHeader title={availableRacers[1].name} image={driver2} />
            }
          />
        </div>
      </div>
    </div>
  );
};
export default CurrentRace;
