import Hero from '../../Components/Hero/Hero';
import Podcast from '../../Components/Homepage/Podcast/Podcast';
import LatestPodcast from './components/LatestPodcast/LatestNews/LatestPodcast';
import NewsList from './components/NewsList/NewsList';
import { heroDataSlider } from '../../Components/Hero/sliderData';

export default function News() {
  return (
    <div id="news-page">
      <Hero props={heroDataSlider} banner={false} />
      <Podcast />
      <LatestPodcast />
      <NewsList />
    </div>
  );
}
