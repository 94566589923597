import React from 'react';
import Uppertitle from '../../../../Components/Uppertitle/Uppertitle';
// import './NewsSlide.scss';

interface IProps {
  img: string;
  date: any;
  title: any;
  subtitle: string;
  tags?: any;
}

const MAX_CHARS: number = 50;
const MAX_CHARS_TITLE: number = 90;

const NewsElement: React.FC<IProps> = ({ ...props }): JSX.Element => {
  return (
    <div className=" radius16 overflow-hidden " id="news-element">
      <img src={props.img} alt="news img" className="img-fluid img-blog" />
      <div className="d-flex p-3 position-relative main-content-container align-items-start justify-content-start gap-2 flex-column h-159">
        <div className="d-flex w-100  align-items-center justify-content-between gap-2">
      
          <div className="w-fit-content d-flex mb-3 flex-wrap">
            {props.tags?.slice(0,1).map((tag: any) => (
              <div className="">
                <Uppertitle
                  background="#031B3F"
                  color="#0BD885"
                  key={tag}
                  uppertitle={tag}
                />
              </div>
            ))}
          </div>
   
          <span>
            {props.date.toLocaleString('en-US', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            })}
          </span>
        </div>
        <span className="font-size-18 font-ri-b black-color lh-1">
          {props.title}
          {/* {props.title.substring(0, MAX_CHARS_TITLE)}
          {props.title.length > MAX_CHARS_TITLE ? '...' : ''} */}
        </span>
        <span className="font-size-16 font-ti-r gray-color">
          {props.subtitle.substring(0, MAX_CHARS)}
          {props.subtitle.length > MAX_CHARS ? '...' : ''}
        </span>
      </div>
    </div>
  );
};

export default NewsElement;
