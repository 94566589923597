import hero from './assets/about-us-hero.png';
import info from './assets/about-us-info.png';
import Team from './Components/Team/Team';

import './AboutUs.scss';
import MeetTheDrivers from '../../Components/Homepage/MeetTheDrivers/MeetTheDrivers';
import Podcast from '../../Components/Homepage/Podcast/Podcast';
import OurPartners from '../../Components/Homepage/OurPartners/OurPartners';
import { about_us } from '../../Globals/aboutUs';
import SliderRight from '../../Components/SliderRight/SliderRight';
import { ReactNode, useRef, useState } from 'react';
import Title from '../../Components/Title/Title';
import LatestPodcast from '../News/components/LatestPodcast/LatestNews/LatestPodcast';

const AboutUs = () => {
  const sliderRef = useRef<ReactNode>(null);
  const [atStart, setAtStart] = useState<boolean>(true);
  const [atEnd, setAtEnd] = useState<boolean>(false);

  return (
    <section id="about-us">
 <div className="hero-partners d-flex p-4">
          <div className="mt-auto container-main mb-4">
            <div className="w-fit-content d-flex mb-4 flex-wrap"></div>
            <Title title="CryptoDATA" size={64} color="#FFFFFF" />
            <Title
              title="RNF MotoGP Team"
              size={80}
              color="#fff"
            />
          </div>
        </div>  
      <div className="container-main section-interspace">
        <div className="row">
          <div className="col-lg-6 col-12 mb-lg-0 mb-5 text-light">
            <h3 className="mb-3 font-size-64 font-ri-sb">About us</h3>
            {about_us.description.paragraphs.map((paragraph, i) => (
              <p key={i} className="font-size-18 font-ti-r">
                {paragraph}
              </p>
            ))}
          </div>
          <div className="col-lg-6 col-12 text-lg-start text-center">
            <img className="img-fluid" src={info} alt="" />
          </div>
        </div>
      </div>

      <div className="container-main section-interspace text-light pt-md-5">
        <div className="row">
          <div className="col-12">
            <h2 className="font-size-64 font-ri-sb mb-md-5">Management</h2>
          </div>
        </div>
        <div className="section-team">
          <SliderRight
            sliderRef={sliderRef}
            atStart={atStart}
            atEnd={atEnd}
            setAtEnd={setAtEnd}
            setAtStart={setAtStart}
          >
            <Team />
          </SliderRight>
        </div>
      </div>

      <MeetTheDrivers />

      <Podcast />
      <LatestPodcast/>

      <OurPartners />
    </section>
  );
};
export default AboutUs;
