export const detectEnd = (
  atEnd: boolean,
  setAtEnd: (value: boolean) => void,
  setAtStart: (value: boolean) => void,
  sliderRef: any
) => {
  let scrollLeft = sliderRef.current.scrollLeft;
  const width = sliderRef.current.scrollWidth;
  if (sliderRef.current.offsetWidth + scrollLeft >= width) {
    if (atEnd === false) {
      setAtEnd(true);
      setAtStart(false);
    }
  }
  if (scrollLeft > 0) {
    setAtStart(false);
  }
  if (atEnd === true) setAtEnd(false);
};

export const detectStart = (
  atStart: boolean,
  setAtStart: (value: boolean) => void,
  setAtEnd: (value: boolean) => void,
  sliderRef: any
) => {
  let scrollLeft = sliderRef.current.scrollLeft;
  const width = sliderRef.current.scrollWidth;
  if (scrollLeft <= 0) {
    if (atStart === false) {
      setAtStart(true);
      setAtEnd(false);
    }
  }
  if (sliderRef.current.offsetWidth + scrollLeft < width) {
    setAtEnd(false);
  }
  if (atStart === true) setAtStart(false);
};
