import { Col, Nav, Row, Tab } from 'react-bootstrap';
import Title from '../../Components/Title/Title';
import { innovation } from '../../Globals/innovation';
import hero from './assets/innovation-hero.png';
import orb from './assets/orb.png';

import './Innovation.scss';

const Innovation = () => {
  return (
    <div id="innovation">
     <div className="hero-partners d-flex p-4">
          <div className="mt-auto container-main mb-4">
            <div className="w-fit-content d-flex mb-4 flex-wrap"></div>
            <Title title="development  " size={64} color="#FFFFFF" />
            <Title title="& Innovation " size={64} color="#FFFFFF" />

          </div>
        </div>  

      <div className="container-main section-interspace text-light">
        <div className="row align-items-center section-interspace">
          <Tab.Container
            id="innovation-tabs"
            defaultActiveKey="Technology Integration"
          >
            <Row className="align-items-center">
              <Col lg={4} xs={12}>
                <Nav className="flex-column">
                  {innovation.tabContent.map((item) => (
                    <Nav.Item key={item.title}>
                      <Nav.Link
                        eventKey={item.title}
                        className="font-size-32 text-light font-ri-sb"
                      >
                        {item.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col lg={8} sm={12} className="mt-lg-0 mt-5">
                <Tab.Content>
                  {innovation.tabContent.map((item) => (
                    <Tab.Pane key={item.title} eventKey={item.title}>
                      <Row className="align-items-center">
                        <Col md={6} xs={12} className="mb-md-0 mb-5">
                          <img className="img-fluid" src={orb} alt="" />
                        </Col>
                        <Col md={6} xs={12}>
                          <h3 className="font-size-48 font-ri-sb">
                            {item.title}
                          </h3>
                          <p className="font-size-20 font-ti-r">
                            {item.description}
                          </p>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>

        <div className="pt-sm-5 pt-1"></div>

        <div className="row align-items-center section-interspace">
          <div className="col-lg-6 mb-lg-0 mb-4 col-12">
            <h3 className="font-size-64 font-ri-sb">
              {innovation.sections.first.title}
            </h3>
            <p className="font-size-20 font-ti-r">
              {innovation.sections.first.description}
            </p>
          </div>
          <div className="col-lg-6 col-12">
            <img
              className="img-fluid"
              src={innovation.sections.first.image}
              alt=""
            />
          </div>
        </div>

        <div className="pt-sm-5"></div>

        <div className="row align-items-center section-interspace pt-5">
          <div className="col-lg-6 mb-lg-0 mb-4 col-12 order-lg-0 order-1">
            <img
              className="img-fluid"
              src={innovation.sections.second.image}
              alt=""
            />
          </div>
          <div className="col-lg-6 col-12">
            <h3 className="font-size-64 font-ri-sb">
              {innovation.sections.second.title}
            </h3>
            <p className="font-size-20 font-ti-r">
              {innovation.sections.second.description}
            </p>
          </div>
        </div>

        <div className="pt-sm-5"></div>

        <div className="row align-items-center section-interspace pt-5">
          <div className="col-lg-6 mb-lg-0 mb-4 col-12">
            <h3 className="font-size-64 font-ri-sb">
              {innovation.sections.third.title}
            </h3>
            <p className="font-size-20 font-ti-r">
              {innovation.sections.third.description}
            </p>
          </div>
          <div className="col-lg-6 mb-4 col-12">
            <img
              className="img-fluid"
              src={innovation.sections.third.image}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Innovation;
