import { INavbarRoute } from "./interfaces";
import { routes } from "./routes";

export const navbarRoutes: INavbarRoute[] = [
    {
        title: "Partners",
        route: [routes.partners],
        submenu: false
    },
    {
        title: "News",
        route: [routes.news],
        submenu: false
    },
    {
        title: "Races",
        route: [routes.races],
        submenu: false
    },
    {
        title: "About Team",
        route: [routes.about],
        submenu: false
    },
    {
        title: "Innovation",
        route: [routes.innovation],
        submenu: false
    },
    // {
    //     title: "Digital Art",
    //     route: [routes.digital],
    //     submenu: false
    // },
    // {
    //     title: "FanZone",
    //     route: [routes.fanZone],
    //     submenu: false,
    //     // submenuItems: [
    //     //     {
    //     //         title: "link1",
    //     //         route: [routes.news]
    //     //     },
    //     //     {
    //     //         title: "link2",
    //     //         route: [routes.races]
    //     //     }
    //     // ]
    // }
];
