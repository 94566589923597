import React from 'react';
import './PodcastSlide.scss';
import Play from '../../../../../../Assets/Global/play-circle.png';
import { url } from 'inspector';

interface IProps {
  img: string;
  date: Date;
  title: string;
  subtitle: string;
  tag: string;
  link: string;
}

const MAX_CHARS: number = 200;
const MAX_CHARS_TITLE: number = 90;

const PodcastSlide: React.FC<IProps> = ({ ...props }): JSX.Element => {
  return (
    <div
      className="slide radius16 overflow-hidden position-relative cursor-pointer"
      onClick={() =>
        //open link
        window.open(props.link, '_blank')
      }
      style={{ height: '420px', maxWidth: '460px',
      background:`url(${props.img})`,
      backgroundPosition:'center',
      backgroundSize:"cover"
    }}
      id="podcast-news"
    >
      <img className="play-button" src={Play}></img>
      <div className="overlay-podcast"></div>
      {/* <img src={props.img} alt="news img" className="podcast-image" /> */}
      <div
        className="d-flex p-3  main-content-container align-items-start justify-content-start gap-2 flex-column "
        style={{ position: 'absolute', bottom: '0px' }}
      >
        <div className="d-flex w-100  align-items-center justify-content-between gap-2">
          <span className="tag-container d-flex align-items-center justify-content-center main-green-color font-size-14 font-ti-b">
            {props.tag}
          </span>
          <span>
            {props.date.toLocaleString('en-US', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            })}
          </span>
        </div>
        <span className="font-size-24 font-ri-b white-color lh-1">
          {props.title.substring(0, MAX_CHARS_TITLE)}
          {props.title.length > MAX_CHARS_TITLE ? '...' : ''}
        </span>
        <span className="font-size-16 font-ti-r " style={{ color: '#CBCBCB' }}>
          {props.subtitle.substring(0, MAX_CHARS)}
          {props.subtitle.length > MAX_CHARS ? '...' : ''}
        </span>
      </div>
    </div>
  );
};

export default PodcastSlide;
