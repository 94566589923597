import management1 from '../Views/AboutUs/assets/management1.png';
import management2 from '../Views/AboutUs/assets/management2.png';
import management3 from '../Views/AboutUs/assets/management3.png';

export interface IManagement {
  image: string;
  name: string;
  position: string;
  role: string;
  description: string[];
}

export interface IData {
  management: IManagement[];
}

export const data: IData = {
  management: [
    {
      image: '',
      name: 'Miguel Oliveira',
      position: 'driver',
      role: 'Driver',
      description: []
    },
    {
      image: '',
      name: 'Raul Fernandez',
      position: 'driver',
      role: 'Driver',
      description: []
    },
    {
      image: management1,
      name: 'Razlan Razali',
      position: 'management',
      role: 'Founder & Team Principal',
      description: [
        'With more than 25 years of diversified experience from Corporate Finance, Construction Industry, Information Technology, Event Management and as CEO of the iconic Sepang International Circuit, Razlan created a MotoGP team driven strongly by passion, experienced team members and with a strong ambition to win the world championship.',
        'From his 11 years experience being the CEO of Sepang International Circuit promoting major mega events such as Formula One and MotoGP, world championship events such as World Superbike, Japan SuperGT, Endurance World Championship and World Touring Cars Championship, Razlan has full understanding and necessary comprehensive knowledge in the world of motorsports.'
      ]
    },
    {
      image: management2,
      name: 'Wilco Zeelenberg',
      position: 'management',
      role: 'Team Manager',
      description: [
        'Started his race career at the age of 17 with racing professionally for 17 years until the age of 34 in the World Championship for a total of 100 GPs.',
        'After 2 years role as a Team Manager in the World Supersport 600, Wilco joined MotoGP as a Team Manager for YAMAHA Factory Team for 9 seasons with Jorge Lorenzo in 2010, 2012 and 2015 that won 3 World Champion.',
        'In 2019 Wilco joined a new MotoGP project with SIC and PETRONAS as a satellite team with Yamaha bikes and aiming to be the best satellite team in MotoGP and successfully achieved that goal.'
      ]
    },
    // {
    //   image: management3,
    //   name: 'Ramon Forcada',
    //   position: 'management',
    //   role: 'Team Manager MotoE Team',
    //   description: [
    //     'Started with off-road racing at the age of 15 years old when still studying and continued racing while in Engineering School in the University. Out of passion, Ramon always did maintenance and tuning of his bikes and he achieved 2 times Champion and 2 runner-up in Catalan Championship.',
    //     'Ramon has been participated in a total of 544 GPs, being a Crew Chief in 516 GPs with the results of 4 World Champions, 9 wins and 149 podiums.',
    //     'In 2023, Ramon is looking forward to a new adventure being a Team Manager, Crew Chief and Mechanic in the new MotoE World Championship with RNF and Ducati bike.'
    //   ]
    // }
  ]
};
