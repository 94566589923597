import React from 'react';
import { ReactNode, useRef, useState } from 'react';
import SliderRight from '../../../../Components/SliderRight/SliderRight';
import CalendarItem, { ICalendarProps } from './CalendarItem';

import './Calendar.scss';

export interface ICalendar {
  calendar: ICalendarProps[];
}

export const Calendar = ({ calendar }: ICalendar): JSX.Element => {
  const sliderRef = useRef<ReactNode>(null);
  const [atStart, setAtStart] = useState<boolean>(true);
  const [atEnd, setAtEnd] = useState<boolean>(false);

  return (
    <div className="calendar section-interspace overflow-hidden">
      <SliderRight
        sliderRef={sliderRef}
        atStart={atStart}
        atEnd={atEnd}
        setAtEnd={setAtEnd}
        setAtStart={setAtStart}
      >
        {calendar?.map((data) => (
          <React.Fragment key={data.date.fullDate}>
            <CalendarItem {...data} />
          </React.Fragment>
        ))}
      </SliderRight>
    </div>
  );
};
export default Calendar;
