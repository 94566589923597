import React, { useRef, useState } from 'react';
import Description from '../../Description/Description';
import Title from '../../Title/Title';
import PLACEHOLDER from '../../../Assets/Homepage/PNG/placeholder.png';
import './OurPartners.scss';
import partner_left_1 from './Assets/sponsor_left_1.png';
import partner_left_2 from './Assets/sponsor_left_2.png';

import partner_right_1 from './Assets/sponsor_right_1.png';
import partner_right_2 from './Assets/sponsor_right_2.png';
import partner_right_3 from './Assets/sponsor_right_3.png';
import partner_right_4 from './Assets/sponsor_right_4.png';
import partner_right_5 from './Assets/sponsor_right_5.png';
import partner_right_6 from './Assets/sponsor_right_6.png';
import partner_right_7 from './Assets/sponsor_right_7.png';
import partner_right_8 from './Assets/sponsor_right_8.png';
import partner_right_9 from './Assets/sponsor_right_9.png';

import partnersRnf from '../../../Assets/Homepage/PNG/partners-rnf.png';

import logo1 from './logo/ 1.png';
import logo2 from './logo/ 2.png';
import logo3 from './logo/ 3.png';
import logo4 from './logo/ 4.png';
import logo5 from './logo/ 5.png';
import logo6 from './logo/ 6.png';
import logo7 from './logo/ 7.png';
import logo8 from './logo/ 8.png';
import logo9 from './logo/ 9.png';
import logo10 from './logo/ 10.png';
import logo11 from './logo/ 11.png';
import logo12 from './logo/ 12.png';
import logo13 from './logo/ 13.png';
import logo14 from './logo/ 14.png';
import logo15 from './logo/ 15.png';
import logo16 from './logo/ 16.png';
import logo17 from './logo/ 17.png';
import logo18 from './logo/ 18.png';
import logo19 from './logo/ 19.png';
import logo20 from './logo/ 20.png';
import logo21 from './logo/ 21.png';
import logo22 from './logo/23.png';
import logo24 from './logo/24.png';

const OurPartners = () => {
  let array = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logo12,
    logo13,
    logo14,
    logo15,
    logo16,
    logo17,
    logo18,
    logo19,
    logo20,
    logo21,
    logo22,
    logo24
  ];
  return (
    <section id="our-partners" className="section-interspace overflow-hidden">
      <div className="container-main">
        <div className="d-flex h-100 align-items-start justify-content-end w-100 container-main gap-3 flex-column">
          <Title title={<>Our Partners</>} size={64} color="#FFFFFF" />
          <Description text="Shared values, mutual trust, and a commitment to excellence. We believe that by working together, we can achieve more and create a better future for all." />
        </div>
        <div className="mt-4">
          <div className="row">
            <div className="col-xl-3 mb-4 col-12 blue-col">
              <div className="content d-flex justify-content-center p-4 align items-center flex-column">
                <div className="m-auto my-md-3 my-4">
                  {' '}
                  <img src={partner_left_1} alt="" />
                </div>
                <div className="m-auto my-md-3 my-4">
                  {' '}
                  <img src={partner_left_2} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-9 mb-4 col-12 green-col">
              <div className="content  d-flex justify-content-center  p-4 align-items-center flex-wrap ">
                <div className="row">
                  {array.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{ flex: '3 3 13%' }}
                        className=" col-xl-3 col-xs-2 col-12 d-flex justify-content-center align-items-center my-3 mx-4"
                      >
                        {' '}
                        <img
                          src={item}
                          style={{ height: '', width: '' }}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    );
                  })}
                  <div
                    style={{ flex: '3 3 25%' }}
                    className=" col-xl-3 col-xs-2 col-12 d-flex justify-content-center align-items-center my-3 mx-4"
                  >
                    {' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurPartners;
