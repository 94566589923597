import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import Track1 from '../../Assets/TrackBanner/png/catalan.png';
import { ReactComponent as Logo1 } from '../../Assets/TrackBanner/svg/logo1.svg';
// import Logo1 from '../../Assets/TrackBanner/png/Lorenzo salvadori nr 32.png';
import { ReactComponent as Logo2 } from '../../Assets/TrackBanner/svg/logo2.svg';
import './TrackBanner.scss';
import axios from 'axios';
import { toast } from 'react-toastify';

const TrackBanner: React.FC = (): JSX.Element => {
  const [res, setRes] = useState({
    LastRace: '',
    LastRaceDate: '',
    NextRace: '',
    NextRaceDate: ''
  });

  const [raceData, setRaceData] = useState({
    Miguel: {
      position: 0,
      points: 0
    },
    Raul: {
      position: 0,
      points: 0
    }
  });
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_RNF_SERVER + '/api/race'
        );

        if (response.status === 200) {
          setRes(response.data.data.attributes);
        }
      } catch (err) {
        // setSliderData(props.props);
        toast.info('Error in loading hero slider data', {
          toastId: 'error-hero-slider'
        });
      }
    };

    const fetchRaceData = async (): Promise<void> => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_RNF_SERVER + '/api/rider/ourTeam'
        );
        if (response.status === 200) {
          console.log('halo', response);
          setRaceData(response.data);
        }
      } catch (err) {
        // setSliderData(props.props);
        toast.info('Error in loading hero slider data', {
          toastId: 'error-hero-slider'
        });
      }
    };
    fetchRaceData();
    fetchData();
  }, []);

  return (
    <section className="z-2 track-banner">
      <div className="container-main position-relative">
        <div className="overflow-container d-flex align-items-center justify-content-center">
          <div className="left-container py-3 pe-3 d-flex align-items-center justify-content-between gap-3">
            <span className="font-size-32 font-ri-r white-color lh-1">
              LAST <br /> RACE
            </span>
            <img
              src={Track1}
              alt="racing track"
              className="img-fluid track-img"
            />
            <div className="d-flex align-items-start justify-content-start flex-column gap-1">
              <div className="white-color font-size-14 font-ti-b date-container radius4 d-flex align-items-center justify-content-center py-1 px-2">
                {res.LastRaceDate}
              </div>
              <span className="white-color font-size-24 font-ri-sb lh-1">
                {res.LastRace}
              </span>
            </div>
            <div className="positions-container ms-auto d-flex align-items-end justify-content-end gap-3">
              <div className="radius8 position-container p-1 p-md-3 d-flex align-items-center justify-content-center gap-2 flex-column">
                <span className="font-ri-b font-size-32 white-color">
                  {raceData?.Miguel?.position}
                </span>
                {/* <Logo1 /> */}
                <Logo1 />{' '}
              </div>
              <div className="radius8 position-container p-1 p-md-3 d-flex align-items-center justify-content-center gap-2 flex-column">
                <span className="font-ri-b font-size-32 white-color">
                  {raceData.Raul.position}
                </span>
                <Logo2 />
              </div>
            </div>
          </div>
          <div className="right-container d-flex align-items-center justify-content-between gap-3">
            <div className="up-next d-flex ps-3 align-items-start justify-content-start gap-3 me-auto">
              <span className="font-size-32 font-ri-r white-color lh-1 my-auto">
                UP <br /> NEXT
              </span>
              <div className="d-flex align-items-start justify-content-start flex-column gap-1">
                <div className="white-color font-size-14 font-ti-b date-container radius4 d-flex align-items-center justify-content-center py-1 px-2">
                  {res.NextRaceDate}
                </div>
                <span className="white-color font-size-24 font-ri-sb lh-1">
                  {res.NextRace}
                </span>
              </div>
            </div>
            <Button
              title="Learn more"
              width={240}
              color="#000000"
              outlineColor="transparent"
              bgColor="#0BD885"
              hoverColor="#000000"
              bgHoverColor="#08FF9B"
              arrow="right"
              customClass="h-100 font-size-24 font-ti-r button-hero"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrackBanner;
