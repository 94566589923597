import React from 'react';
import Button from '../../../../Components/Button/Button';
import Description from '../../../../Components/Description/Description';
import Title from '../../../../Components/Title/Title';

import './VoteHelmet.scss';

const VoteHelmet = () => {
  return (
    <section id="vote-helmet" className="section-interspace px-2">
      <div className="d-flex h-100 align-items-start justify-content-end my-4 py-4 w-100 container-main gap-3 flex-column">
        <Title
          title={
            <>
Vote your favorite<br></br> helmet


            </>
          }
          size={64}
          color="#FFFFFF"
        />
        <Description 
        text="It's time to show your support for your favorite rider's helmet design! Cast your vote and let us know which helmet is your top pick. Whether it's the bold graphics, sleek lines, or unique color scheme, each helmet design reflects the personality and style of the rider. This is your chance to win amazing prizes, share your passion for MotoGP with your friends and help your favorite rider come out on top. Don't miss the opportunity to be a part of this exciting event and make your voice heard!
        " />
{/* 
        <Button
          title={'Learn more'}
          arrow={'right'}
          color={'#0BD885'}
          outlineColor={'transparent'}
          bgColor={'transparent'}
          hoverColor={'#08FF9B'}
          align={'start'}
          fontSize="font-size-24"
          specialPadding={'0px'}
          customClass="font-ti-r"
        /> */}
      </div>
    </section>
  );
};

export default VoteHelmet;
