import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const PageTracking = () => {
  const location = useLocation();
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize('G-5K9JXTJ7NQ');
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      ReactGA.send("pageview");
    }
  }, [init, location]);
};

export default PageTracking;
