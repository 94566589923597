import React from 'react';


const RaceFeatures = ({ feature }: any): JSX.Element => {
  return (
    <React.Fragment>

        <div
          key={feature.value}
          className="feature d-flex gap-2 align-items-center justify-content-center"
        >
          <div className="px-2 py-2 lh-1 my-1 d-flex gap-2 rounded-1">
            <span className="font-size-18 font-ri-r text-light">
              {feature.name}:
            </span>
            <span className="font-size-18 font-ri-r main-green-color">
              {feature.value}
            </span>
          </div>
        </div>

    </React.Fragment>
  );
};
export default RaceFeatures;
