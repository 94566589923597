import React from 'react';
import { useNavigate } from 'react-router-dom';
import Title from '../../../Components/Title/Title';
import Uppertitle from '../../../Components/Uppertitle/Uppertitle';
import { IBlog } from '../Blog';
import { RiCalendar2Line } from 'react-icons/ri';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import LatestNews from '../../../Components/Homepage/LatestNews/LatestNews';

type Props = {
  item: IBlog;
};

export default function BlogPage({ item }: Props) {
  const nav = useNavigate();

  const goBack = () => {
    nav(-1);
  };

  console.log(item);

  const createAnchorTag = (urlString: any) => {
    const regex = /(.*)\[(.*?)\]\((.*?)\)(.*)/;
    const matches = regex.exec(urlString);

    if (matches && matches.length >= 5) {
      const prefix = matches[1];
      const anchorText = matches[2];
      const url = matches[3];
      const suffix = matches[4];

      return (
        <React.Fragment>
          {prefix}
          <a href={url} className="green-color-link" target="_blank">
            {anchorText}
          </a>
          {suffix}
        </React.Fragment>
      );
    }

    return urlString;
  };

  return (
    <div>
      <div
        className="navero-useNavigate hero-blog p-4"
        style={{
          backgroundImage: `url(${item.bg})`
        }}
      >
        <div className="mt-auto container-main">
          <div className="w-fit-content d-flex mb-3 flex-wrap">
            {item.tags?.map((tag: any) => (
              <div className="m-1">
                <Uppertitle
                  background="#031B3F"
                  color="#0BD885"
                  key={tag}
                  uppertitle={tag}
                />
              </div>
            ))}
          </div>
          <Title title={item.title} size={64} color="#FFFFFF" />
        </div>
      </div>

      <div className="container-main">
        {/* <p>{item.date}</p> */}
        <div className="article-meta col-md-10 offset-md-1 mt-5 mb-4">
          <div className="d-flex gap-4">
            <button
              onClick={goBack}
              className="d-flex align-items-center gap-2 bg-transparent border-0 p-0 text-light"
            >
              <HiArrowNarrowLeft size="20" color="#0bd885" />
              <span className="font-size-16 font-ti-r">Go back</span>
            </button>
            <div className="d-flex align-items-center gap-2">
              <RiCalendar2Line size="20" color="#0bd885" />
              <span className="font-size-16 font-ti-r">{item.date}</span>
            </div>
          </div>
        </div>
        {item.article?.map((content: any) => {
          if (content.type === 'text') {
            return (
              <div
                key={content.subtitle}
                className="font-size-18 col-md-10 offset-md-1 fonr-ti-r light-gray-color text-start lh-13 mb-4"
              >
                <h3>{content.subtitle}</h3>
                <div className="font-ti-r font-size-18">
                  {createAnchorTag(content.content)}
                </div>
              </div>
            );
          } else if (content.type === 'quote') {
            if (content.textAfterQuote) {
              return (
                <div
                  key={content.subtitle}
                  className="font-size-18 col-md-10 offset-md-1 fonr-ti-r light-gray-color text-start lh-13 mb-5"
                >
                  <h3>{content.textAfterQuote.split('\n')[0]}</h3>
                  <div className="font-ti-r font-size-18">
                    {createAnchorTag(content.textAfterQuote.split('\n')[1])}
                  </div>
                </div>
              );
            }
            return (
              <div className="quote-blog my-5">
                <h3 className="font-ri-b font-size-32 mb-5 ">
                  {content.subtitle}
                </h3>

                <blockquote
                  className="font-ti-r font-size-24"
                  key={content.subtitle}
                >
                  {content.content}
                </blockquote>
              </div>
            );
          } else if (content.type === 'image') {
            if (typeof content.content === 'string') {
              return (
                <div key={content.subtitle} className="my-5">
                  <img className="img-fluid" src={content.content} alt="" />
                </div>
              );
            }
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
}
