import { ICalendarProps } from '../Views/Races/Components/Calendar/CalendarItem';

import germany_track from '../Views/Races/assets/tracks/16_jun_germany.png';
import british from '../Views/Races/assets/tracks/british.png';
import catalan from '../Views/Races/assets/tracks/catalan.svg'
import rimini from '../Views/Races/assets/tracks/rimini.svg'
import japan from '../Views/Races/assets/tracks/japan.svg'
import indonesia from '../Views/Races/assets/tracks/indonesia.svg'
import australia from  '../Views/Races/assets/tracks/australia.svg'
import thailand from  '../Views/Races/assets/tracks/thailand.svg'
import malaysia from  '../Views/Races/assets/tracks/malaysia.svg'
import qatar from  '../Views/Races/assets/tracks/qatar.svg'
export const calendar: ICalendarProps[] = [
  // {
  //   active: true,
  //   event: {
  //     name: 'GERMAN GP',
  //     type: 'motogp',
  //     image: germany_track
  //   },
  //   circuit: {
  //     name: 'Liqui Moly Motorrad Grand Prix Deutschland',
  //     length: '3.67 Km',
  //     trackWidth: '12 M',
  //     leftCorners: '10',
  //     rightCorners: '3',
  //     longestStraight: '700 M'
  //   },
  //   country: {
  //     name: 'Germany',
  //     flag: '🇩🇪'
  //   },
  //   date: {
  //     day: 16,
  //     month: 'Jun',
  //     fullDate: '16 Jun 2023'
  //   }
  // },

  // ============================== 8
  // {
  //   active: true,
  //   event: {
  //     name: 'DUTCH GP',
  //     type: 'motogp',
  //   image: british

  //   },
  //   circuit: {
  //     name: 'TT Circuit Assen',
  //     length: '',
  //     trackWidth: '',
  //     leftCorners: '',
  //     rightCorners: '',
  //     longestStraight: ''
  //   },
  //   country: {
  //     name: 'Holland',
  //     flag: '🇳🇱'
  //   },
  //   date: {
  //     day: 23,
  //     month: 'Jun',
  //     fullDate: '23 - 25 Jun 2023'
  //   }
  // },

  // ============================== 9
  // {
  //   active: false,
  //   event: {
  //     name: 'KAZAKHSTAN GP**',
  //     type: 'motogp',
  //     image: ''
  //   },
  //   circuit: {
  //     name: 'Sokol International Racetrack',
  //     length: '',
  //     trackWidth: '',
  //     leftCorners: '',
  //     rightCorners: '',
  //     longestStraight: ''
  //   },
  //   country: {
  //     name: 'Kazakhstan',
  //     flag: '🇰🇿'
  //   },
  //   date: {
  //     day: 7,
  //     month: 'Jul',
  //     fullDate: '7 - 9 Jul 2023'
  //   }
  // },

  // ============================== 10
  // {
  //   active: false,
  //   event: {
  //     name: 'BRITISH GP',
  //     type: 'motogp',
  //     image: british
  //   },
  //   circuit: {
  //     name: 'Silverstone Circuit',
  //     length: '',
  //     trackWidth: '',
  //     leftCorners: '',
  //     rightCorners: '',
  //     longestStraight: ''
  //   },
  //   country: {
  //     name: 'United Kingdom',
  //     flag: '🇬🇧'
  //   },
  //   date: {
  //     day: 4,
  //     month: 'Aug',
  //     fullDate: '4 - 6 Aug 2023'
  //   }
  // },

  // // ============================== 11
  // {
  //   active: false,
  //   event: {
  //     name: 'AUSTRIAN GP',
  //     type: 'motogp',
  //     image: ''
  //   },
  //   circuit: {
  //     name: 'Red Bull Ring - Spielberg',
  //     length: '',
  //     trackWidth: '',
  //     leftCorners: '',
  //     rightCorners: '',
  //     longestStraight: ''
  //   },
  //   country: {
  //     name: 'Austria',
  //     flag: '🇦🇹'
  //   },
  //   date: {
  //     day: 18,
  //     month: 'Aug',
  //     fullDate: '18 - 20 Aug 2023'
  //   }
  // },

  // ============================== 12
  // {
  //   active: true,
  //   event: {
  //     name: 'CATALAN GP',
  //     type: 'motogp',
  //     image: catalan,
  //   },
  //   circuit: {
  //     name: 'Circuit de Barcelona-Circuit de Catalunya',
  //     length: '',
  //     trackWidth: '',
  //     leftCorners: '',
  //     rightCorners: '',
  //     longestStraight: ''
  //   },
  //   country: {
  //     name: 'Spain',
  //     flag: '🇪🇸'
  //   },
  //   date: {
  //     day: 1,
  //     month: 'Sep',
  //     fullDate: '1 - 3 Sep 2023'
  //   }
  // },

  // ============================== 13
  // {
  //   active: false,
  //   event: {
  //     name: 'SAN MARINO GP',
  //     type: 'motogp',
  //     image: rimini,
  //   },
  //   circuit: {
  //     name: 'Misano World Circuit Marco Simoncelli',
  //     length: '',
  //     trackWidth: '',
  //     leftCorners: '',
  //     rightCorners: '',
  //     longestStraight: ''
  //   },
  //   country: {
  //     name: 'San Marino',
  //     flag: '🇸🇲'
  //   },
  //   date: {
  //     day: 8,
  //     month: 'Sep',
  //     fullDate: '8 - 10 Sep 2023'
  //   }
  // },

  // // ============================== 14
  // {
  //   active: false,
  //   event: {
  //     name: 'INDIAN GP**',
  //     type: 'motogp',
  //     image: ''
  //   },
  //   circuit: {
  //     name: 'Buddh International Circuit',
  //     length: '',
  //     trackWidth: '',
  //     leftCorners: '',
  //     rightCorners: '',
  //     longestStraight: ''
  //   },
  //   country: {
  //     name: 'India',
  //     flag: '🇮🇳'
  //   },
  //   date: {
  //     day: 22,
  //     month: 'Sep',
  //     fullDate: '22 - 24 Sep 2023'
  //   }
  // },

  // ============================== 15
  // {
  //   active: false,
  //   event: {
  //     name: 'JAPANESE GP',
  //     type: 'motogp',
  //     image: japan
  //   },
  //   circuit: {
  //     name: 'Mobility Resort Motegi',
  //     length: '',
  //     trackWidth: '',
  //     leftCorners: '',
  //     rightCorners: '',
  //     longestStraight: ''
  //   },
  //   country: {
  //     name: 'Japan',
  //     flag: '🇯🇵'
  //   },
  //   date: {
  //     day: 29,
  //     month: 'Sep',
  //     fullDate: '29 Sep - 1 Oct 2023'
  //   }
  // },

  // ============================== 16
  // {
  //   active: false,
  //   event: {
  //     name: 'INDONESIAN GP',
  //     type: 'motogp',
  //     image: indonesia
  //   },
  //   circuit: {
  //     name: 'Pertamina Mandalika Circuit',
  //     length: '',
  //     trackWidth: '',
  //     leftCorners: '',
  //     rightCorners: '',
  //     longestStraight: ''
  //   },
  //   country: {
  //     name: 'Indonesia',
  //     flag: '🇮🇩'
  //   },
  //   date: {
  //     day: 13,
  //     month: 'Oct',
  //     fullDate: '13 - 15 Oct 2023'
  //   }
  // },

  // ============================== 17
  {
    active: true,
    event: {
      name: 'AUSTRALIAN GP',
      type: 'motogp',
      image: australia
    },
    circuit: {
      name: 'Phillip Island',
      length: '',
      trackWidth: '',
      leftCorners: '',
      rightCorners: '',
      longestStraight: ''
    },
    country: {
      name: 'Australia',
      flag: '🇦🇺'
    },
    date: {
      day: 20,
      month: 'Oct',
      fullDate: '20 - 22 Oct 2023'
    }
  },

  // ============================== 18
  {
    active: false,
    event: {
      name: 'THAI GP',
      type: 'motogp',
      image: thailand
    },
    circuit: {
      name: 'Chang International Circuit',
      length: '',
      trackWidth: '',
      leftCorners: '',
      rightCorners: '',
      longestStraight: ''
    },
    country: {
      name: 'Thailand',
      flag: '🇹🇭'
    },
    date: {
      day: 27,
      month: 'Oct',
      fullDate: '27 - 29 Oct 2023'
    }
  },

  // ============================== 19
  {
    active: false,
    event: {
      name: 'MALAYSIAN GP',
      type: 'motogp',
      image: malaysia
    },
    circuit: {
      name: 'Sepang International Circuit',
      length: '',
      trackWidth: '',
      leftCorners: '',
      rightCorners: '',
      longestStraight: ''
    },
    country: {
      name: 'Malaysia',
      flag: '🇲🇾'
    },
    date: {
      day: 10,
      month: 'Nov',
      fullDate: '10 - 12 Nov 2023'
    }
  },

  // ============================== 20
  {
    active: false,
    event: {
      name: 'QATAR GP*',
      type: 'motogp',
      image: qatar
    },
    circuit: {
      name: 'Lusail International Circuit',
      length: '',
      trackWidth: '',
      leftCorners: '',
      rightCorners: '',
      longestStraight: ''
    },
    country: {
      name: 'Qatar',
      flag: '🇶🇦'
    },
    date: {
      day: 17,
      month: 'Nov',
      fullDate: '17 - 19 Nov 2023'
    }
  }
];
