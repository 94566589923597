import React, { useEffect, useState } from 'react';
import Title from '../../Title/Title';
import Toggler from '../../Toggler/Toggler';
import { ReactComponent as Logo1 } from '../../../Assets/Homepage/SVG/meet-logo1.svg';
import Logo2 from '../../../Assets/Homepage/PNG/meet-logo2.png';
import Flag1 from '../../../Assets/Homepage/PNG/meet-flag1.png';
import Flag2 from '../../../Assets/Homepage/PNG/meet-flag2.png';
import './MeetTheDrivers.scss';
import Button from '../../Button/Button';
import axios from 'axios';

interface Driver {
  name: string;
  position: string;
  points: string;
  time?: null;
  qualification?: null;
  topSpeed?: null;
  championship: string;
}

interface DriversData {
  [key: string]: Driver;
}

const MeetTheDrivers: React.FC = (): JSX.Element => {
  const [toggler, setToggler] = useState<boolean>(false);
  const [drivers, setDrivers] = useState<DriversData>({});

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_RNF_SERVER + '/api/rider/ourTeam'
        );

        if (response.status === 200) {
          setDrivers(response.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  return (
    <section id="meet-driver" className="section-interspace overflow-hidden">
      <div className="d-flex h-100 align-items-center justify-content-center w-100 container-main gap-3 flex-column">
        <Title title={<>MEET OUR RIDERS</>} size={64} color="#FFFFFF" />
        {/* <Toggler
          title1="title1"
          title2="title2"
          state={toggler}
          setter={setToggler}
        /> */}
        <div className="d-lg-flex align-items-center justify-content-center gap-3 w-100 mt-5">
          <div className="info-container info-container1 d-flex align-items-end justify-content-between flex-column p-3 p-lg-4 radius8 position-relative">
            <Logo1 />
            <div className="d-flex align-items-end justify-content-center gap-4">
              <div className="p-container radius8 font-size-32 font-ri-b white-color d-flex align-items-center justify-content-center">
                {drivers['Miguel']?.championship}
              </div>
              <div className="p-container radius8 d-flex align-items-center justify-content-center flex-column gap-1">
                <span className="font-size-32 font-ri-b white-color lh-1">
                  {drivers['Miguel']?.points}
                </span>
                <span className="font-size-14 font-ri-b white-color lh-1">
                  POINTS
                </span>
              </div>
            </div>
            <img src={Flag1} alt="portugal flag" className="img-fluid" />
            <span className="font-size-48 font-ri-b white-color">
              {drivers['Miguel']?.name}
            </span>
          </div>
          <div className="info-container info-container2 d-flex align-items-start justify-content-between flex-column p-3 p-lg-4 radius8 position-relative">
            <img src={Logo2} alt="" className="img-fluid" />

            <div className="d-flex align-items-end justify-content-center gap-4">
              <div className="p-container radius8 font-size-32 font-ri-b white-color d-flex align-items-center justify-content-center">
                {drivers['Raul']?.championship}
              </div>
              <div className="p-container radius8 d-flex align-items-center justify-content-center flex-column gap-1">
                <span className="font-size-32 font-ri-b white-color lh-1">
                  {drivers['Raul']?.points}
                </span>
                <span className="font-size-14 font-ri-b white-color lh-1">
                  POINTS
                </span>
              </div>
            </div>
            <img src={Flag2} alt="spain flag" className="img-fluid" />
            <span className="font-size-48 font-ri-b white-color">
              {drivers['Raul']?.name}
            </span>
            {/* <Button
              title={'View profile'}
              arrow={'right'}
              color={'#0BD885'}
              outlineColor={'transparent'}
              bgColor={'transparent'}
              hoverColor={'#08FF9B'}
              align={'start'}
              fontSize="font-size-24"
              specialPadding={'0px'}
              customClass="font-ti-r"
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeetTheDrivers;
