import React, { useState, useEffect } from 'react';
import { ISliderRightProps } from '../../../Globals/interfaces';
// import { ReactComponent as LeftArrow } from "../../../Views/Homepage/WhyChoose/Assets/chevron_left.svg"
// import { ReactComponent as RightArrow } from "../../../Views/Homepage/WhyChoose/Assets/chevron_right.svg"
import Button from '../../Button/Button';
import { detectEnd, detectStart } from '../SliderUtils';
import './SliderButtons.scss';

const SliderButtons: React.FC<ISliderRightProps> = ({
  sliderRef,
  atEnd,
  setAtEnd,
  atStart,
  setAtStart
}): JSX.Element => {
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      detectStart(atStart, setAtStart, setAtEnd, sliderRef);
      detectEnd(atEnd, setAtEnd, setAtStart, sliderRef);
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [rerender]);

  return (
    <div
      className={`slider-right-buttons d-flex align-items-end ms-auto justify-content-end gap-3`}
    >
      <Button
        title={''}
        height={48}
        minWidth={48}
        outlineColor={'#0BD885'}
        color={atStart ? '#0BD885' : '#000000'}
        bgColor={atStart ? 'transparent' : '#0BD885'}
        hoverColor={atStart ? '#000000' : '#000000'}
        callback={(e) => {
          e.preventDefault();
          let scrollLeft = sliderRef.current.scrollLeft;
          sliderRef.current.scroll({
            left: scrollLeft - 250,
            behavior: 'smooth'
          });
          setRerender(!rerender);
        }}
        bgHoverColor={atStart ? '#086841' : '#0BD885'}
        arrow="left"
        disableBtn={atStart}
        customClass="radius8"
      />

      <Button
        title={''}
        height={48}
        minWidth={48}
        outlineColor={'#0BD885'}
        color={atEnd ? '#0BD885' : '#000000'}
        bgColor={atEnd ? 'transparent' : '#0BD885'}
        hoverColor={atEnd ? '#000000' : '#000000'}
        callback={(e) => {
          e.preventDefault();
          let scrollLeft = sliderRef.current.scrollLeft;
          sliderRef.current.scroll({
            left: scrollLeft + 250,
            behavior: 'smooth'
          });
          setRerender(!rerender);
        }}
        bgHoverColor={atEnd ? '#086841' : '#0BD885'}
        arrow="right"
        disableBtn={atEnd}
        customClass="radius8"
      />
    </div>
  );
};

export default SliderButtons;
