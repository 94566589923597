import { IBlog, IBlogArticle, IServerBlog, ITag } from '../Views/Blog/Blog';
import { monthNames } from './arrays';

export const changeDateFormat = (blogDate: string): string => {
  try {
    //date is format year-month-day
    const [year, month, day] = blogDate.toString().split('-');

    return monthNames[parseInt(month) - 1] + ' ' + parseInt(day) + ', ' + year;
  } catch (err) {
    return '';
  }
};

export const serverBlogsToBlogs = (serverBlogs: IServerBlog[]): IBlog[] => {
  let newBlogs: IBlog[] = [];

  for (let blog of serverBlogs) {
    //get tags
    let serverTags: ITag[] = blog['tags'];

    let article: IBlogArticle[] = [];
    //add article paragraphs
    blog['article'].forEach((elem, i) => {
      article.push({
        type: 'text',
        content: elem['articleParagraph']
      });
    });

    //add quotes
    blog['quote'].forEach((elem, i) => {
      article.push({
        type: 'quote',
        subtitle: elem['author'],
        content: elem['quote'],
        textAfterQuote: elem['optionalTextAfterQuote']
      });
    });

    //add end image
    if (blog['bottomImage'] !== null) {
      article.push({
        type: 'image',
        content: process.env.REACT_APP_RNF_SERVER + blog['bottomImage']['url']
      });
    }

    //save blog
    newBlogs.push({
      bg: process.env.REACT_APP_RNF_SERVER + blog['background']['url'],
      title: blog['title'],
      slug: blog['slug'],
      shortDescription: blog['shortDescription'],
      tags: serverTags.map((elem, i) => elem['name']),
      date: changeDateFormat(blog['date']),
      article: article
    });
  }

  return newBlogs;
};
