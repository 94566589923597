import React from 'react';
import { data } from '../../../../Globals/globalVariables';
import { ReactComponent as CirclePlus } from '../../assets/circle-plus.svg';
import { ReactComponent as CloseCirclePlus } from '../../assets/close-circle-plus.svg';

const Team = () => {
  const [active, setActive] = React.useState<string | null>(null);

  const management = data.management.filter(
    (member) => member.position === 'management'
  );

  return (
    <React.Fragment>
      {management.map((member, i) => (
        <div key={i + member.name} className="team-card">
          <div className="member-card position-relative overflow-hidden">
            <div
              className={`member-description text-center ${
                active === member.name ? 'active' : ''
              }`}
            >
              <h3 className="member-name font-size-48 font-ri-sb main-green-color px-4">
                {member.name}
              </h3>
              <p className="member-position font-size-24 font-ti-r px-4">
                {member.role}
              </p>
              {member.description
                ? member.description.map((desc: string, i: number) => (
                    <div key={i} className="desc px-4">
                      <p className="font-size-18 font-ti-r">{desc}</p>
                    </div>
                  ))
                : null}
              <button
                onClick={() => setActive(null)}
                className="bg-transparent p-0 rounded-0 border-0 hide-description"
              >
                <CloseCirclePlus />
              </button>
            </div>
            <div className="member-info">
              <h3 className="member-name font-size-48 font-ri-sb main-green-color">
                {member.name}
              </h3>
              <p className="member-position font-size-24 font-ti-r">
                {member.role}
              </p>
            </div>
            <div className="member-image">
              <img src={member.image} alt="" />
            </div>
            {member.description.length ? (
              <button
                onClick={() => setActive(member.name)}
                className="bg-transparent p-0 rounded-0 border-0 show-description"
              >
                <CirclePlus />
              </button>
            ) : null}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};
export default Team;
