import React from 'react';
import Button from '../../Button/Button';
import Description from '../../Description/Description';
import Title from '../../Title/Title';
import './Podcast.scss';

const Podcast = () => {
  return (
    <section id="podcast" className="section-interspace">
      <div className="d-flex h-100 align-items-start justify-content-end w-100 container-main gap-3 flex-column">
        <Title
          title={
            <>
              get ready for the<br></br>
              ultimate racing podcast
            </>
          }
          size={64}
          color="#FFFFFF"
        />
        <Description text="Tune in to RNF Unlocked, a podcast series that gives you exclusive insider access to the CryptoDATA RNF MotoGP Team. Get ready for thrills, spills, and everything in between as we deep dive into the MotoGP season." />

        {/* <Button
          title={'Learn more'}
          arrow={'right'}
          color={'#0BD885'}
          outlineColor={'transparent'}
          bgColor={'transparent'}
          hoverColor={'#08FF9B'}
          align={'start'}
          fontSize="font-size-24"
          specialPadding={'0px'}
          customClass="font-ti-r"
        /> */}
      </div>
    </section>
  );
};

export default Podcast;
