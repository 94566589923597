import axios from "axios";
import { useEffect, useState } from "react";

export interface IRaceHeaderProps {
  image: string;
  title: string;
  subtitle?: string;
  circuit?: string;
  position?: string;
  type?: 'driver' | 'circuit';
}

const RaceHeader = ({ ...data }: IRaceHeaderProps) => {

  const [res,setRes]=useState()
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_RNF_SERVER + '/api/race'
        );

        if (response.status === 200) {
          setRes(
            response.data.data.attributes.DateInterval
          );
 
        }
      } catch (err) {
        // setSliderData(props.props);
       
      }
    };

    fetchData();
  }, []);

  return (
    <div className="d-flex flex-column text-center">
      <div className={`race-image ${data?.type ? data?.type : ''}`}>
        <img className="img-fluid" src={data.image} alt="" />
      </div>

      <h3 className="font-ri-sb font-size-40 text-light text-center mt-3 mb-3">
        {data.title}
      </h3>

      {data.position ? (
        <div className="text-center">
          <img className="qualifier-position" src={data.position} alt="" />
        </div>
      ) : null}

      {data.subtitle ? (
        <span className="font-size-24 font-ri-l text-light">
     {res}
        </span>
      ) : null}

      {data.circuit ? (
        <span className="font-size-20 text-light font-ti-r mt-3">
          {data.circuit}
        </span>
      ) : null}
    </div>
  );
};
export default RaceHeader;
