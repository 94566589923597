import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../Components/Button/Button';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="py-5 my-5 not-found">
      <div className="container-main section-interspace text-center">
        <h2 className="font-size-120 font-ti-b text-light">404</h2>
        <p className="font-size-32 font-ri-r gray-color lh-1">
          Sorry, there's <br /> nothing here
        </p>
        <Button
          title="Go home"
          height={56}
          heightResponsive={56}
          heightResponsive580={56}
          color="#000000"
          outlineColor="transparent"
          bgColor="#0BD885"
          hoverColor="#000000"
          bgHoverColor="#08FF9B"
          customClass="z-2 radius8 m-auto mt-5 font-ri-m font-size-20"
          callback={() => navigate('/')}
        />
      </div>
    </div>
  );
};

export default NotFound;
