import React, { useEffect, useRef, useState } from 'react';
import Description from '../../../../../Components/Description/Description';
import SliderButtons from '../../../../../Components/SliderRight/SliderButtons/SliderButtons';
import SliderRight from '../../../../../Components/SliderRight/SliderRight';
import Title from '../../../../../Components/Title/Title';

import PLACEHOLDER from '../../../../../Assets/Homepage/PNG/placeholder.png';
import podcast from '../../../../../Assets/Homepage/PNG/podcast1.jpg';
import './LatestPodcast.scss';
import PodcastSlide from './NewsSlide/PodcastSlide';
import { Placeholder } from 'react-bootstrap';
import { IServerImage } from '../../../../Blog/Blog';
import axios from 'axios';
import { toast } from 'react-toastify';

interface IServerPodcast {
  title: string;
  description: string;
  link: string;
  date: Date;
  slug: string;
  id: number;
  background: IServerImage;
}

interface IPodcast {
  img: string;
  date: Date;
  link: string;
  title: string;
  subtitle: string;
  tag: string;
}

const data = [
  {
    img: podcast,
    date: new Date(),
    link: 'https://youtu.be/njXTyN1DZ5k',
    title:
      'Pilot Episode: 2023 Season Debut - The Controversies | RNF Unlocked Podcast',
    subtitle:
      'In this debut episode hosted by Neil Morrison, we’re joined by Team Principal, Razlan Razali and Team Manager, Wilco Zeelenberg as they talk about the team’s season debut and the controversies behind Miguel Oliveira’s unfortunate crash.',
    tag: 'Podcast'
  }
];

const LatestPodcast = () => {
  const sliderRef = useRef<any>(null);
  const [atStart, setAtStart] = useState<boolean>(true);
  const [atEnd, setAtEnd] = useState<boolean>(false);

  const [podcasts, setPodcasts] = useState<IPodcast[]>([]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_RNF_SERVER + '/api/podcast/allPodcasts'
        );
        if (response.status === 200) {
          let podcastsList: IServerPodcast[] = response.data;

          setPodcasts(
            podcastsList.map((elem, i) => {
              return {
                img:
                  process.env.REACT_APP_RNF_SERVER + elem['background']['url'],
                date: elem.date,
                link: elem['link'],
                title: elem['title'],
                subtitle: elem['description'],
                tag: 'Podcast'
              };
            }).sort((a, b) => {
              return new Date(b.date).getTime() - new Date(a.date).getTime();
            }
              )
          );

        
        }
      } catch (err) {
        toast.info('Error in loading podcasts', { toastId: 'error-podcasts' });
      }
    };

    fetchData();
  }, []);

  return (
    <section id="latest-podcast" className="section-interspace overflow-hidden">
      <div className="d-flex h-100 align-items-start justify-content-end w-100 container-main gap-3 flex-column">
        {/* <Title title={<>Latest news</>} size={64} color="#FFFFFF" />
        <Description text="From race recaps and riders’ interview to technical insights and team events, we provide you with the latest news from the team throughout our MotoGP World Championship journey. " />
        <SliderButtons
          sliderRef={sliderRef}
          atStart={atStart}
          atEnd={atEnd}
          setAtEnd={setAtEnd}
          setAtStart={setAtStart}
        /> */}
      </div>

      <SliderRight
        sliderRef={sliderRef}
        atStart={atStart}
        atEnd={atEnd}
        setAtEnd={setAtEnd}
        setAtStart={setAtStart}
      >
        {podcasts.map((el, i) => {
          return (
            <React.Fragment key={i}>
              <PodcastSlide
                img={el.img}
                title={el.title}
                subtitle={el.subtitle}
                date={el.date}
                link={el.link}
                tag={el.tag}
              />
            </React.Fragment>
          );
        })}
      </SliderRight>
    </section>
  );
};

export default LatestPodcast;
