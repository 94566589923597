import React from "react";
import { IUppertitle } from "../../Globals/interfaces";
import "./Uppertitle.scss";

const Uppertitle: React.FC<IUppertitle> = ({ ...props }): JSX.Element => {
  return (
    <div
      style={{ background: props.background }}
      className="radius18 px-3 z-2 py-1 d-flex align-items-center justify-content-center"
    >
      <span className="font-size-14 font-ti-b" style={{ color: props.color }}>
        {props.uppertitle}
      </span>
    </div>
  );
};

export default Uppertitle;
