import orb from '../Views/Innovation/assets/orb.png';
export const innovation = {
  tabContent: [
    {
      title: 'Technology Integration',
      description:`By integrating the technology developed and patented by CryptoDATA Tech, we can better understand the sentiment analysis of specific topics related to MotoGP racing. With this technology, we can create a platform for our fans and sponsors to track real-time opinions and sentiments about RNF and its partners. This will provide valuable insights into the public's perception of our team and allow us to stay connected with our supporters. We are excited to bring this innovative technology to the forefront of MotoGP and continue to elevate the sport with the latest advancements.
      `
    },
    {
      title: 'Artificial Intelligence',
      description:
`The AI-based software developed and patented by CryptoDATA helps us improve our performance both on and off the track. By leveraging Artificial Intelligence, we can conduct high-end sentiment analysis, gaining valuable insights into how fans and partners perceive our team and activities. 
`    },
    {
      title: 'Research & Development',
      description:
      `Research and Development (R&D) is a critical aspect of our MotoGP team's success. We're constantly pushing the boundaries of what's possible, exploring new technologies and techniques that help us achieve our goals. Our R&D team is made up of experts in their respective fields, who work tirelessly to develop new innovations that improve our performance both on and off-track.
      `
    },
    {
      title: 'Data Security',
      description:`Data security is a top priority for our team, and we're committed to ensuring that all confidential information is kept safe and secure. To achieve this, together with CryptoDATA’s specialists, we are using the latest encryption algorithms, including the innovative Voice Over Blockchain Protocol (VOBP) to ensure the highest level of security for our data, preventing unauthorized access and ensuring that our sensitive information remains protected. We continually review and update our data security measures to stay ahead of any potential threats, so our fans, sponsors, and partners can be confident in our commitment to protecting their information.
      `

    },
    {
      title: 'Data Analytics',
      description:
`Our team relies on cutting-edge data analytics to gain insights into our performance. By using CryptoDATA's AI-based data analytics tools, we can identify patterns and trends in performance data, allowing us to make data-driven decisions that help us perform better and achieve our goals. We also perform sentiment analysis  to get valuable insights into how fans and partners perceive our team and activities. This helps us to continually improve and make decisions that have a positive impact on our team's success.
`    }
  ],
  sections: {
    first: {
      title: 'Resources optimisation',
      description:
`Our team is committed to achieving high performance on MotoGP's premier grid. With the integration of CryptoDATA's technology, we're able to optimize our resources to streamline processes, reduce costs, and improve efficiency, so we can focus on delivering top performances on and off-track.
`,      image: orb
    },
    second: {
      title: 'Sustainable development',
      description:
`Sustainable development is a key priority for our team. We are committed to minimizing our environmental impact and leaving a positive legacy for future generations. This involves reducing our carbon footprint through energy-efficient practices, waste reduction, and responsible resource management. By embracing sustainable development, we believe we can continue to compete at the highest level while also being responsible global citizens.
`,      image: orb
    },
    third: {
      title: 'Environment protection',
      description:`We recognize the impact that motorsports can have on the planet and are committed to reducing our carbon footprint. We implement a range of sustainable practices to minimize our environmental impact, including reducing waste, recycling, and using energy-efficient equipment. We also strive to raise awareness of environmental issues among our fans and partners, promoting responsible behavior and eco-friendly practices. We believe that by taking action to protect the environment, we can create a more sustainable future for generations to come.
      `,

      image: orb
    }
  }
};
