import { IRoute } from "./interfaces";

export const routes: IRoute = {
    home: "/",
    news: "/news",
    partners: "/partners",
    races: "/races",
    about: "/about-team",
    innovation: "/innovation",
    digital: "/digital-art",
    fanZone: "/fanzone",
    error404: "*"
}