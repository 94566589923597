import React, { useEffect, useRef, useState } from 'react';
import SliderRight from '../../../../Components/SliderRight/SliderRight';
import Title from '../../../../Components/Title/Title';

import PLACEHOLDER from '../../../../Assets/Homepage/PNG/placeholder.png';
import './NewsList.scss';
import NewsSlide from '../../../../Components/Homepage/LatestNews/NewsSlide/NewsSlide';
import NewsElement from '../NewsElement/NewsElement';
import './NewsList.scss';
import Masonry from 'react-masonry-css';

import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IBlog } from '../../../Blog/Blog';
import { serverBlogsToBlogs } from '../../../../Globals/functions';
import LatestNews from '../../../../Components/Homepage/LatestNews/LatestNews';

const NewsList = () => {
  const navigate = useNavigate();

  const sliderRef = useRef<any>(null);
  const [atStart, setAtStart] = useState<boolean>(true);
  const [atEnd, setAtEnd] = useState<boolean>(false);

  const handlePhotoSize = (number: any) => {
    switch (number) {
      case 0:
        return 'h-320';
      case 1:
        return 'h-420';
      case 2:
        return 'h-320';
      case 3:
        return 'h-420';
      case 4:
        return 'h-342';
      case 5:
        return 'h-320';
      case 6:
      case 7:
        return 'h-300';
      default:
        const offset = (number - 8) % 4;
        switch (offset) {
          case 0:
            return 'h-320';
          case 1:
            return 'h-420';
          case 2:
            return 'h-342';
          default:
            return 'h-300';
        }
    }
  };

  const breakpointColumnsObj = {
    default: 4,
    1000: 3,
    800: 2,
    600: 1
  };

  // create a hook that determine screen size
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  // update the state on resize
  const handleResize = () => setScreenSize(window.innerWidth);

  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_RNF_SERVER + '/api/blog/allBlogs'
        );

        if (response.status === 200) {
          setBlogs(serverBlogsToBlogs(response.data));
          setIsLoading(false);
        }
      } catch (err) {
        toast.info('Error in loading news', { toastId: 'error-news' });
      }
    };

    fetchData();
  }, []);

  // add event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    // remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isLoading) {
    return <></>;
  }

  return (
    <section id="news-list" className="section-interspace overflow-hidden">
      <div className="d-flex h-100 align-items-start justify-content-end w-100 container-main gap-3 flex-column">
        <Title title={<> News</>} size={64} color="#FFFFFF" />
      </div>

      {screenSize > 600 ? (
        <div className="container-main my-3">
          <div className="d-flex flex-wrap ">
            <div className=" diff-size-img d-flex flex-wrap">
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {blogs.map((el, i) => {
                  return (
                    <Link
                      to={`/blog/${el.slug}`}
                      className={`mb-4 border-12 d-inline-block text-decoration-none ${handlePhotoSize(
                        i
                      )}`}
                      key={i}
                    >
                      <NewsElement
                        img={el.bg}
                        title={el.title}
                        subtitle={el.shortDescription}
                        date={el.date}
                        tags={el.tags}
                      />
                    </Link>
                  );
                })}
              </Masonry>
            </div>
          </div>
        </div>
      ) : (
        <LatestNews />
      )}
    </section>
  );
};

export default NewsList;
