import Hero from '../../Components/Hero/Hero';
import './Fanzone.scss';
import Podcast from '../../Components/Homepage/Podcast/Podcast';
import ActiveContest from './Components/ActiveContest/ActiveContest';
import VoteHelmet from './Components/VoteHelmet/VoteHelmet';

import { heroDataSlider } from '../../Components/Hero/sliderData';

export default function Fanzone() {
  return (
    <>
      {' '}
      <Hero props={heroDataSlider} banner={false} />
      <ActiveContest />
      {/* <RNFParty /> */}
      <VoteHelmet />
      {/* <InstagramRNF /> */}
      {/* <LiveNow /> */}
      <Podcast />
      {/* <LatestPodcast/> */}
    </>
  );
}
