import React from 'react';
import Button from '../../../../Components/Button/Button';
import Description from '../../../../Components/Description/Description';
import Title from '../../../../Components/Title/Title';

import './ActiveContest.scss';

const Podcast = () => {
  return (
    <section id="active-contest" className="section-interspace px-2">
      <div className="d-flex h-100 align-items-start justify-content-end w-100 my-4 py-4 container-main gap-3 flex-column">
        <Title
          title={
            <>Guess the pole <br></br>
position time
            </>
          }
          size={64}
          color="#FFFFFF"
        />
        <Description 
        text="Want to win amazing prizes like a limited-edition T-shirt signed by the team or two VIP Village passes? It's time to predict the pole position for the next race and get as close as possible to the actual time. The more accurate your prediction, the higher your chances of being one of the lucky winners. So, get ready for the excitement and put your prediction skills to the test!
        " />
{/* 
        <Button
          title={'Learn more'}
          arrow={'right'}
          color={'#0BD885'}
          outlineColor={'transparent'}
          bgColor={'transparent'}
          hoverColor={'#08FF9B'}
          align={'start'}
          fontSize="font-size-24"
          specialPadding={'0px'}
          customClass="font-ti-r"
        /> */}
      </div>
    </section>
  );
};

export default Podcast;
