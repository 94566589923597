import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LatestNews from '../../Components/Homepage/LatestNews/LatestNews';
import './Blog.scss';
import BlogPage from './BlogPage/BlogPage';
import axios from 'axios';
import { serverBlogsToBlogs } from '../../Globals/functions';
import { toast } from 'react-toastify';

type Props = {};
export interface IBlogArticle {
  type: string;
  subtitle?: string;
  content: JSX.Element | string;
  textAfterQuote?: string | null;
}

export interface ITag {
  name: string;
}

export interface IArticle {
  articleParagraph: string;
}

export interface IQuote {
  author: string;
  quote: string;
  optionalTextAfterQuote: string | null;
}

export interface IServerImage {
  name: string;
  formats: any;
  mime: string;
  url: string;
}

export interface IServerBlog {
  title: string;
  shortDescription: string;
  date: string;
  slug: string;
  id: number;
  background: IServerImage;
  bottomImage: IServerImage | null;
  article: IArticle[];
  quote: IQuote[];
  tags: ITag[];
}

export interface IBlog {
  bg: string;
  title: string | JSX.Element;
  slug: string;
  shortDescription: string;
  tags: string[];
  date: string;
  article: IBlogArticle[];
}
export default function Blog({}: Props) {
  const [param, setParam] = React.useState(0);
  const [currentBlog, setCurrentBlog] = React.useState<IBlog[]>([]);

  const navigate = useLocation();

  // set id to param
  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const id = window.location.pathname.split('/')[2];

        const response = await axios.get(
          process.env.REACT_APP_RNF_SERVER + '/api/blog/withSlug/' + id
        );

        if (response.status === 200) {
          setCurrentBlog(serverBlogsToBlogs([response.data]));
        }
      } catch (err) {
        toast.info('Error in loading blog data', { toastId: 'error-blog' });
      }
    };

    fetchData();
  }, [navigate.state]);

  return (
    <>
      <div id="blog-page">
        <div className="white-color">
          {currentBlog.length > 0 &&
            currentBlog?.map((it) => <BlogPage key={it.slug} item={it} />)}
        </div>
        <LatestNews />
      </div>
    </>
  );
}
