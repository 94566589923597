import React from 'react';
import { ITitle } from '../../Globals/interfaces';

const Title: React.FC<ITitle> = ({ ...props }): JSX.Element => {
  return (
    <div
      className={`title-component z-2 lh-1 font-ri-b  font-size-${props.size} fw-normal`}
      style={{ color: props.color }}
    >
      {props.title}
    </div>
  );
};

export default Title;
