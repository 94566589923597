import React from 'react';
import partners from '../assets/partners.png';
import green from '../assets/greenpower.png';
import only from '../assets/only.png';
import sterilgarda from '../assets/newpartners/sterilgarda.png';
import estella from '../assets/newpartners/estella.png';
import ixon from '../assets/ixon.png';
import wrs from '../assets/wrs.png';
import castrol from '../assets/newpartners/castrol.png';
import rcb from '../assets/rcb.png';
import michelin from '../assets/michelin.png';
import wispr from '../assets/wispr.png';
import cryptodata from '../assets/cryptodata.png';
import sprint from '../assets/newpartners/sprint.png';

import zixxar from '../assets/newpartners/zixxar.png';
import airdry from '../assets/newpartners/airdry.png';
import aprilia from '../assets/newpartners/aprilia.png';
import beta from '../assets/newpartners/beta.png';
import giacomell from '../assets/newpartners/giacomell.png';
import ponte from '../assets/newpartners/ponte.png';
import rnf from '../assets/newpartners/rnf.png';
import sc from '../assets/newpartners/sc.png';
import shark from '../assets/newpartners/shark.png';
import winery from '../assets/newpartners/winery.png';
import xiden from '../assets/newpartners/xiden.png';
import regina from '../assets/newpartners/regina.png';
import boh from '../assets/newpartners/boh.png';

type Props = {};

export default function PartnersList({}: Props) {
  let partnerslist = [
    {
      title: 'Title Sponsor',
      partners: [
        {
          name: 'Cryptodata',
          img: cryptodata,
          link: 'https://cryptodata.com'
        },
        {
          name: 'WispR',
          img: wispr,
          link: 'https://cryptodata.com/wispr'
        }
      ]
    },
    {
      title: 'Team partners',
      partners: [
        {
          name: 'Aprilia',
          img: aprilia,
          link: 'https://www.aprilia.com/'
        },
        {
          name: 'Green power generators',
          img: green,
          link: 'https://www.greenpowergen.com/en/'
        },
        {
          name: 'Sterilgarda',
          img: sterilgarda,
          link: 'https://www.sterilgarda.it/en/'
        },
        {
          name: 'Zixxar',
          img: zixxar,
          link: 'https://www.zixxar.net/'
        },
        {
          name: 'Xiden',
          img: xiden,
          link: 'https://www.xiden.com/'
        },
        {
          name: ' RNF MotoGP Team',
          img: rnf,
          link: 'https://www.rnfracing.com/'
        },

        {
          name: 'Estrella Galicia',
          img: estella,
          link: 'https://estrellagalicia.es/'
        },
        {
          name: 'RCB',
          img: rcb,
          link: 'https://racingboy.com.my'
        },
        {
          name: 'Shark',
          img: shark,
          link: 'https://www.shark-helmets.com/'
        },
        {
          name: 'WRS',
          img: wrs,
          link: 'https://www.wrs.it/en/'
        },
        {
          name: 'Ixon',
          img: ixon,

          link: 'https://www.ixon.com/gbr-en/'
        }
      ]
    },
    {
      title: 'Technical Partners',
      partners: [
        {
          name: 'Castrol',
          img: castrol,
          link: 'https://www.castrol.com/'
        },
        {
          name: 'Beta',
          img: beta,
          link: 'https://www.beta-tools.com/en/'
        },
        {
          name: 'Sprint Filter',
          img: sprint,
          link: 'https://www.sprintfilter.net'
        },
        {
          name: 'Regina Chain ',
          img: regina,
          link: 'https://www.reginachain.net/'
        },

        {
          name: 'Sc Project',
          img: sc,
          link: 'https://sc-project.com.au'
        },
        {
          name: 'Michelin',
          img: michelin,
          link: 'https://www.michelin.com/'
        }
      ]
    },
    {
      title: 'HOSPITALITY Partners',
      partners: [
        {
          name: 'Caffe Giacomelli',
          img: giacomell,
          link: 'http://www.caffegiacomelli.com '
        },
        {
          name: 'Ponte',
          img: ponte,
          link: 'https://www.ponte1948.it/en/veneto-winemakers/ '
        },

        {
          name: 'Corte scalleta',
          img: winery,
          link: 'https://www.cortescaletta.it/en/'
        },
        {
          name: 'Airdry',
          img: airdry,
          link: 'https://airdry.com.my/'
        },
        {
          name: 'BOH',
          img: boh,
          link: 'https://bohtea.com'
        }
      ]
    }
  ];

  return (
    <div id="partnerslist" className="mt-5">
      <div className="container-main mb-4">
        <div className="title-partners font-size-64 font-ri-b mb-4">
          Title Sponsor
        </div>
        <div className="partners-list row ">
          {partnerslist[0].partners.map((item, index) => {
            return (
              <div
                key={index}
                className="partners-item w-md-auto   col-md-4 mb-4"
              >
                <a href={item.link} target="_blank" className="w-fit-content">
                  <img
                    src={item.img}
                    alt="partners-item"
                    className="blue-box-shadow img-fluid"
                  />
                  <div className="sponsor-name font-size-40 font-ri-b white-color text-center">
                    {item.name}
                  </div>
                </a>{' '}
              </div>
            );
          })}
        </div>
      </div>
      <div className="container-main mb-4">
        <div className="title-partners font-size-64 font-ri-b green-color mb-4">
          Official Partners
        </div>
        <div className="partners-list row ">
          {partnerslist[1].partners.map((item, index) => {
            return (
              <div
                key={index}
                className="partners-item w-md-auto   col-md-4 mb-4"
              >
                <a href={item.link} target="_blank" className="w-fit-content">
                  <img
                    src={item.img}
                    alt="partners-item"
                    className="img-fluid green-box-shadow"
                  />
                  <div className="sponsor-name font-size-40 font-ri-b white-color text-center">
                    {item.name}
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div className="container-main mb-5">
        <div className="title-partners font-size-64 font-ri-b red-color mb-4">
          technical partners
        </div>
        <div className="partners-list row ">
          {partnerslist[2].partners.map((item, index) => {
            return (
              <div
                key={index}
                className="partners-item w-md-auto   col-md-4 mb-4"
              >
                <a href={item.link} target="_blank" className="w-fit-content">
                  <img
                    src={item.img}
                    alt="partners-item"
                    className="img-fluid red-box-shadow"
                  />
                  <div className="sponsor-name font-size-40 font-ri-b white-color text-center">
                    {item.name}
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
      <div className="container-main mb-5">
        <div className="title-partners font-size-64 font-ri-b orange-color mb-4">
          HOSPITALITY PARTNER
        </div>
        <div className="partners-list row ">
          {partnerslist[3].partners.map((item, index) => {
            return (
              <div
                key={index}
                className="partners-item w-md-auto   col-md-4 mb-4"
              >
                <a href={item.link} target="_blank" className="w-fit-content">
                  <img
                    src={item.img}
                    alt="partners-item"
                    className="img-fluid red-box-shadow rounded"
                  />
                  <div className="sponsor-name font-size-40 font-ri-b white-color text-center">
                    {item.name}
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
