import { ReactNode } from 'react';

export interface IRaceColumnProps {
  head: ReactNode;
  features?: ReactNode;
}

const RaceColumn = ({ head, features }: IRaceColumnProps) => {
  return (
    <>
      {head}
      <div className="mt-3">{features}</div>
    </>
  );
};

export default RaceColumn;
