import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './NewsSlide.scss';

interface IProps {
  img: string;
  date: any;
  title?: any;
  subtitle?: any;
  tag?: any;
  slug?: string;
}

const MAX_CHARS: number = 50;
const MAX_CHARS_TITLE: number = 100;

const NewsSlide: React.FC<IProps> = ({ ...props }): JSX.Element => {
  return (
    <Link
      to={`/blog/${props.slug}`}
      state={{rerender:true}}
      className="slide radius16 overflow-hidden h-100 user-select-auto text-decoration-none"
      id="slide-news"
    >
      <img
        style={{ minHeight: '65%' }}
        src={props.img}
        alt="news img"
        className="img-fluid img-blog"
      />
      <div className="d-flex p-3 position-relative main-content-container align-items-start justify-content-start gap-2 flex-column">
        <div className="d-flex w-100  align-items-center justify-content-between gap-2">
          <span className="tag-container d-flex align-items-center justify-content-center main-green-color font-size-14 font-ti-b">
            {props.tag}
          </span>
          <span>
            {props.date.toLocaleString('en-US', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            })}
          </span>
        </div>
        <span className="font-size-24 font-ri-b black-color lh-1">
          {/* {props.title.substring(0, MAX_CHARS_TITLE)}
          {props.title.length > MAX_CHARS_TITLE ? '...' : ''} */}
          {props.title}
        </span>
        <span className="font-size-16 font-ti-r gray-color">
          {props.subtitle.substring(0, MAX_CHARS)}
          {props.subtitle.length > MAX_CHARS ? '...' : ''}
        </span>
      </div>
    </Link>
  );
};

export default NewsSlide;
