import twopoints from '../../Globals/assets/twopoints.jpg';
// added by me
import returnToEurope from '../../Globals/assets/returnToEurope.jpg';
import sundayRace from '../../Globals/assets/sundayRace.jpg';
import americasQualification from '../../Globals/assets/americasQualification.jpg';
import rollercoaster from '../../Globals/assets/rollercoaster.jpg';
import americas from '../../Globals/assets/americas.jpg';

export const heroDataSlider = [
  // {
  //   bg: loading,
  //   title: <>Loading</>,
  //   callback: () => {}
  // }
  {
    bg: returnToEurope,
    title: <>CryptoDATA RNF returns to Europe..</>,
    callback: () =>
      window.location.replace(
        '/blog/crypto-data-rnf-moto-gp-team-returns-to-europe'
      )
  },
  {
    bg: sundayRace,
    title: <>CryptoDATA RNF MotoGP Team...</>,
    callback: () =>
      window.location.replace(
        '/blog/crypto-data-rnf-moto-gp-team-prepares-for-grand-prix-of-the-americas'
      )
  },
  {
    bg: americasQualification,
    title: <>Two points for Oliveira and a top 15...</>,
    callback: () =>
      window.location.replace(
        '/blog/two-points-for-oliveira-and-a-top-15-for-fernandez-in-saturday-s-heat-sprint'
      )
  },
  {
    bg: rollercoaster,
    title: <>Rollercoaster Friday for CryptoDATA RNF...</>,
    callback: () =>
      window.location.replace(
        '/blog/rollercoaster-friday-for-cryptodata-rnf-motogp-team-at-cota'
      )
  },
  {
    bg: americas,
    title: <>CryptoDATA RNF MotoGP prepares...</>,
    callback: () =>
      window.location.replace(
        '/blog/cryptodata-rnf-motogp-team-prepares-for-grand-prix-of-the-americas'
      )
  },
  {
    bg: returnToEurope,
    title: <>CryptoDATA RNF returns to Europe..</>,
    callback: () =>
      window.location.replace(
        '/blog/return-to-europe'
      )
  },
  {
    bg: sundayRace,
    title: <>CryptoDATA RNF MotoGP Team...</>,
    callback: () =>
      window.location.replace(
        '/blog/cryptodata-rnf-motogp-team-prepares-for-grand-prix-of-the-americas-2'
      )
  },
  {
    bg: americasQualification,
    title: <>Two points for Oliveira and a top 15...</>,
    callback: () =>
      window.location.replace(
        '/blog/two-points-for-oliveira-and-a-top-15-for-fernandez-in-saturdays-heat-sprint'
      )
  },
  {
    bg: rollercoaster,
    title: <>Rollercoaster Friday for CryptoDATA RNF...</>,
    callback: () =>
      window.location.replace(
        '/blog/rollercoaster-friday-for-cryptodata-rnf-motogp-team-at-cota'
      )
  },
  {
    bg: americas,
    title: <>CryptoDATA RNF MotoGP prepares...</>,
    callback: () =>
      window.location.replace(
        '/blog/cryptodata-rnf-motogp-team-prepares-for-grand-prix-of-the-americas'
      )
  },
  {
    bg: twopoints,
    title: <>First two points for Raul Fernandez in 2023</>,
    callback: () =>
      window.location.replace(
        '/blog/first-two-points-for-raul-fernandez-in-2023'
      )
  }
];
