import React, { useEffect, useRef, useState } from 'react';
import Description from '../../Description/Description';
import SliderRight from '../../SliderRight/SliderRight';
import Title from '../../Title/Title';
import './LatestNews.scss';
import SliderButtons from '../../SliderRight/SliderButtons/SliderButtons';
import NewsSlide from './NewsSlide/NewsSlide';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IBlog } from '../../../Views/Blog/Blog';
import { serverBlogsToBlogs } from '../../../Globals/functions';

const LatestNews = () => {
  const sliderRef = useRef<any>(null);
  const [atStart, setAtStart] = useState<boolean>(true);
  const [atEnd, setAtEnd] = useState<boolean>(false);
  const id = window.location.pathname.split('/')[2];

  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_RNF_SERVER + '/api/blog/allBlogs'
        );

        if (response.status === 200) {
          setBlogs(serverBlogsToBlogs(response.data));
          setIsLoading(false);
        }
      } catch (err) {
        toast.info('Error in loading latest news', {
          toastId: 'error-latest-news'
        });
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <></>;
  }

  return (
    <section id="latest-news" className="section-interspace overflow-hidden">
      <div className="d-flex h-100 align-items-start justify-content-end w-100 container-main gap-3 flex-column">
        <Title title={<>Latest news</>} size={64} color="#FFFFFF" />
        <Description text="From race recaps and riders’ interview to technical insights and team events, we provide you with the latest news from the team throughout our MotoGP World Championship journey. " />
        <SliderButtons
          sliderRef={sliderRef}
          atStart={atStart}
          atEnd={atEnd}
          setAtEnd={setAtEnd}
          setAtStart={setAtStart}
        />
      </div>

      <SliderRight
        sliderRef={sliderRef}
        atStart={atStart}
        atEnd={atEnd}
        setAtEnd={setAtEnd}
        setAtStart={setAtStart}
      >
        {blogs
          .filter((item, i) => {
            return item.slug !== id;
          })
          .map((el, i) => {
            return (
              <React.Fragment key={i}>
                <NewsSlide
                  img={el.bg}
                  title={el.title}
                  subtitle={el.shortDescription}
                  date={el.date}
                  tag={el.tags[0]}
                  slug={el.slug}
                />
              </React.Fragment>
            );
          })}
      </SliderRight>
    </section>
  );
};

export default LatestNews;
