import React from 'react';
import Hero from '../../Components/Hero/Hero';
import BG1 from '../../Assets/Homepage/PNG/bg.png';
import BG2 from '../../Assets/Homepage/PNG/bg2.png';
import BG3 from '../../Assets/Homepage/PNG/bg3.png';
import BG4 from '../../Assets/Homepage/PNG/bg4.png';
import PartnersList from './components/PartnersList';
import './Partners.scss';
import OurPartners from '../../Components/Homepage/OurPartners/OurPartners';
import Title from '../../Components/Title/Title';
import img from './assets/bg.png';
import { useNavigate } from 'react-router-dom';

import portugal from '../..//Globals/assets/portugal.jpg';
import cryptodata from '../..//Globals/assets/cryptodata.jpg';
import castrol from '../..//Globals/assets/castrol.jpg';

import oliveira from '../..//Globals/assets/oliveira.jpg';
import Description from '../../Components/Description/Description';
import cdt from '../..//Globals/assets/cdt.jpg';
import rnfPreview from '../..//Globals/assets/RNFPreviewPhoto.jpg';

import roalerCoaster from '../..//Globals/assets/roller-coaster.jpg';
import qualification from '../..//Globals/assets/qualifications.jpg';
import unlucky from '../..//Globals/assets/unlucky.jpg';
import injury from '../..//Globals/assets/injury.jpg';


type Props = {};

export default function Partners({}: Props) {
  const navigate = useNavigate();

 

  return (
    <>
      <div id="partners">
        <div className="hero-partners d-flex p-4">
          <div className="mt-auto container-main mb-4">
            <div className="w-fit-content d-flex mb-4 flex-wrap"></div>
            <Title title="our partners" size={64} color="#FFFFFF" />
            <Title
              title="Alone we can do so little, 
together we can achieve so much."
              size={48}
              color="#0BD885"
            />
          </div>
        </div>
        <PartnersList />
        <OurPartners />
      </div>
    </>
  );
}
