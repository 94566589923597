import React from 'react';
import { Helmet } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes } from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
// import PageTracking from './Helpers/PageTracking';
import Homepage from './Views/Homepage/Homepage';
import NotFound from './Views/NotFound/NotFound';
import { ToastContainer, toast } from 'react-toastify';
import Races from './Views/Races/Races';
import AboutUs from './Views/AboutUs/AboutUs';
import Innovation from './Views/Innovation/Innovation';
import Partners from './Views/Partners/Partners';
import News from './Views/News/News';
import Fanzone from './Views/Fanzone/Fanzone';
import Blog from './Views/Blog/Blog';
import PageTracking from './Helpers/PageTracking';

const App = () => {
  PageTracking();

  return (
    <>
      <Helmet>
        <title>RNF Racing MotoGP Team</title>

      </Helmet>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        closeButton={true}
      />
      <Navbar />

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/races" element={<Races />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/news" element={<News />} />
        <Route path="/fanzone" element={<Fanzone />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/about-team" element={<AboutUs />} />
        <Route path="/innovation" element={<Innovation />} />
        <Route path="/blog/:id" element={<Blog />} />
      </Routes>

      <Footer />
    </>
  );
};

export default App;
