import React from 'react';
import './Footer.scss';
import MainLogo from '../../Assets/Homepage/PNG/rnf_logo_v2.png';
import Title from '../Title/Title';

import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../Globals/routes';
import axios from 'axios';
import { toast } from 'react-toastify';

const Footer: React.FC = (): JSX.Element => {
  const [email, setEmail] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);


 const handleSubmit = async (event:any) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://subscribe.rnfracing.com/subscribers/subscribeToNewsletter', {
        email: email
      });
      if (response.status === 200) {
       toast.success('Thank you for subscribing to our newsletter');
        setSuccess(true);
      }
    } catch (error) {
      // this.setState({ success: false, error: error.message });
      toast.error('Something went wrong');
    }
  }

  const navigate = useNavigate();
  return (
    <div id="footer">
      <div className="container-main">
        <div className="text-center">
          <img
            src={MainLogo}
            alt="main-logo"
            className="img-fluid mb-4 main-logo"
          />
          <Title color="#fff" title="Sign up to our newsletter" size={48} />
          <div
            className="font-size-18 fonr-ti-r mt-3 light-gray-color text-center lh-13 m-auto mb-5"
            style={{ maxWidth: '650px' }}
          >
            Stay up-to-date with our latest achievements, behind-the-scenes
            stories, and expert insights from the world of CryptoDATA RNF
            Racing.
          </div>
{!success &&      <div className="d-flex justify-content-center mb-5">
            {' '}
            <input
              placeholder="enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              className="input-rnf input-subscribe "
            />
            <Button
              title="Send"
              height={56}
              heightResponsive={56}
              heightResponsive580={56}
              color="#000000"
              outlineColor="transparent"
              bgColor="#0BD885"
              hoverColor="#000000"
              bgHoverColor="#08FF9B"
              customClass="z-2 radius8 font-ri-m font-size-20"
              callback={(e) => handleSubmit(e)}
            />
          </div>}
    

        </div>
        <hr />
        <div className="row  mt-3 ">
          <div className=" col-md-3 col-12 white-color font-ti-r  d-flex justify-content-md-start justify-content-center">
            <div>RNF Racing MotoGP Team ​© 2023</div>
          </div>
          <div className="col-md-6 col-12  ">
            <div className="row">
              <div
                onClick={(e) => navigate(routes.partners)}
                className="white-color text-center col-md-2 col-12 cursor-pointer mt-md-0 mt-4"
              >
                Partners
              </div>
              <div
                onClick={(e) => navigate(routes.news)}
                className="white-color text-center col-md-2 col-12 cursor-pointer mt-md-0 mt-4"
              >
                News
              </div>
              <div
                onClick={(e) => navigate(routes.races)}
                className="white-color text-center col-md-2 col-12 cursor-pointer mt-md-0 mt-4"
              >
                Races
              </div>
              <div
                onClick={(e) => navigate(routes.about)}
                className="white-color  text-center col-md-2 col-12 cursor-pointer mt-md-0 mt-4"
              >
                About Team
              </div>
              <div
                onClick={(e) => navigate(routes.innovation)}
                className="white-color text-center col-md-2 col-12 cursor-pointer mt-md-0 mt-4"
              >
                Innovation
              </div>
              {/* <div
                onClick={(e) => navigate(routes.fanZone)}
                className="white-color text-center col-md-2 col-12 cursor-pointer mt-md-0 mt-4"
              >
                Fanzone
              </div> */}
            </div>
          </div>
          <div className="socials col-md-3 col-12 d-flex justify-content-md-end mt-md-0 mt-4 justify-content-center">
            <a
              href="https://www.facebook.com/RNFRacingOfficial/"
              target="_blank"
              className="white-color font-ri-r font-size-18"
            >
              <span style={{ color: '#0B81D8' }}>| </span> FB{' '}
              <span style={{ color: '#890BD8' }}>| </span>
            </a>
            <a
             href="https://www.instagram.com/rnfracingofficial/"
              target="_blank"
              className="white-color font-ri-r font-size-18"
            >
              IG <span style={{ color: '#0BA1D8' }}>| </span>
            </a>
            <a
   
              href="https://twitter.com/rnfracingteam/"
              target="_blank"
              className="white-color font-ri-r font-size-18"
            >
              TW <span style={{ color: '#D80B0B' }}>| </span>
            </a>
            <a
              href="https://www.youtube.com/channel/UCW2PGSJtyl0FquNhoDjWP6A"
              target="_blank"
              className="white-color font-ri-r font-size-18"
            >
              YT <span style={{ color: '#848484' }}>| </span>{' '}
            </a>
            <a
              href="https://www.tiktok.com/@rnfracingofficial?lang=en"
              target="_blank"
              className="white-color font-ri-r font-size-18"
            >
              TK{' '}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
