import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button/Button';
import Title from '../../Title/Title';
import './AboutRacing.scss';

const AboutRacing = () => {
  const nav = useNavigate();

  return (
    <section id="about-racing" className="section-interspace">
      <div className="d-flex h-100 align-items-start justify-content-end w-100 container-main gap-3 flex-column">
        <Title
          title={
            <>
              About cryptodata <br />
              rnf racing
            </>
          }
          size={64}
          color="#FFFFFF"
        />

        <Button
          title={'Learn more'}
          arrow={'right'}
          color={'#0BD885'}
          outlineColor={'transparent'}
          bgColor={'transparent'}
          hoverColor={'#08FF9B'}
          align={'start'}
          fontSize="font-size-24"
          specialPadding={'0px'}
          customClass="font-ti-r"
          callback={() => nav('/about-team')}
        />
      </div>
    </section>
  );
};

export default AboutRacing;
