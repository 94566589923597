import { ReactNode, useRef, useState } from 'react';
import { BsFillCloudArrowDownFill } from 'react-icons/bs';
import Button from '../../../../Components/Button/Button';
import SliderRight from '../../../../Components/SliderRight/SliderRight';
import { gallery } from '../../../../Globals/imageGallery';
import './ImageGallery.scss';

const ImageGallery = () => {
  const sliderRef = useRef<ReactNode>(null);
  const [atStart, setAtStart] = useState<boolean>(true);
  const [atEnd, setAtEnd] = useState<boolean>(false);
  const [mainImage, setMainImage] = useState<string>(gallery[0]);

  return (
    <div className="image-gallery container-main section-interspace">
      <div className="row">
        <div className="col-12">
          <div className="main-gallery">
            <img src={mainImage} alt="" />
          </div>
        </div>
        <div className="secondary-gallery mt-2 overflow-hidden">
          <SliderRight
            sliderRef={sliderRef}
            atStart={atStart}
            atEnd={atEnd}
            setAtEnd={setAtEnd}
            setAtStart={setAtStart}
          >
            {gallery.map((image) => (
              <div
                key={image}
                className={`secondary-gallery-item ${
                  mainImage === image ? 'active' : ''
                }`}
              >
                <img onClick={() => setMainImage(image)} src={image} alt="" />
              </div>
            ))}
          </SliderRight>
     
        </div>
      <div>
        <Button
            title="More Photos"
            height={48}
            heightResponsive={48}
            heightResponsive580={36}
            color="#000000"
            outlineColor="transparent"
            bgColor="#0BD885"
            hoverColor="#000000"
            bgHoverColor="#08FF9B"
            customClass="z-2 radius8 font-ri-m font-size-20 m-auto"
            callback={(e) => {
              //open link in a new tab
              window.open(
                'https://www.flickr.com/photos/194703680@N04/albums',
                '_blank'
              );
            }}
            // callback={test}
          /></div>
      </div>
      
    </div>
  );
};

export default ImageGallery;
