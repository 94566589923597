import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Accordion, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MainLogo from '../../Assets/Homepage/PNG/rnf_logo_v2.png';
import { ReactComponent as ShopCart } from '../../Assets/Navbar/SVG/shopping-bag.svg';
import { INavbarRoute, IRoute, ISubmenu } from '../../Globals/interfaces';
import { navbarRoutes } from '../../Globals/navbarRoutes';
import { AiOutlinePlus as Plus } from 'react-icons/ai';
import './Navbar.scss';

const Navbar: React.FC = (props): JSX.Element => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const ref = useRef<any>(null);
  const closeRef = useRef<any>(null);
  const navigate = useNavigate();

  const handleNavigation = (e: React.MouseEvent, route: INavbarRoute) => {
    // e.stopPropagation();
    if (!route?.submenu) {
      setExpanded(false);
      navigate(route.route[0]);
    } else {
      navigate(route.route[0]);
    }
  };

  const toggleNavbar = () => {
    setExpanded((prev) => !prev);
  };

  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        closeRef.current &&
        !closeRef.current.contains(event.target)
      ) {
        if (expanded) {
          setExpanded(false);
        }
      }
    },
    [ref, closeRef, expanded]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [ref, expanded, handleClickOutside]);

  return (
    <nav className="container-navbar w-100 d-flex align-items-center justify-content-between my-3">
      <Link to="/">
        <img src={MainLogo} alt="main-logo" className="img-fluid main-logo" />
      </Link>
      <div className="container-main">
        <ul className="links-container mb-0 w-100 d-none d-lg-flex align-items-center justify-content-around gap-3">
          {navbarRoutes.map((route: INavbarRoute, index: number) => {
            if (!route.submenu)
              return (
                <li
                  key={index}
                  className={`navbar-route font-size-18 font-ti-r text-unselectable ${
                    location.pathname === route.route[0] ? 'active-route' : ''
                  }`}
                  onClick={(e: React.MouseEvent) => handleNavigation(e, route)}
                >
                  {route.title}
                </li>
              );
            else
              return (
                <React.Fragment key={index}>
                  <DropdownButton
                    id={`${
                      route.route.includes(location.pathname)
                        ? 'dropdown-button-active'
                        : 'dropdown-button'
                    }`}
                    title={route.title}
                    menuVariant="dark"
                  >
                    {route?.submenuItems?.map(
                      (item: ISubmenu, index2: number) => {
                        return (
                          <Dropdown.Item
                            key={index2}
                            className=" font-size-18 font-ti-r "
                            onClick={(e) =>
                              handleNavigation(
                                e,
                                item as unknown as INavbarRoute
                              )
                            }
                          >
                            <span
                              className={` font-size-18 font-ti-r ${
                                location.pathname === item.route[0]
                                  ? 'active-sub-route'
                                  : ''
                              }`}
                            >
                              {item.title}
                            </span>
                          </Dropdown.Item>
                        );
                      }
                    )}
                  </DropdownButton>
                </React.Fragment>
              );
          })}
        </ul>
      </div>
      <div className="buy-container d-flex align-items-center justify-content-end">
        {/* <button className="buy-button d-flex align-items-center justify-content-center d-none d-lg-flex">
          <ShopCart />
        </button> */}
        <div
          ref={closeRef}
          className={`button-menu-mobile d-flex d-lg-none ${
            expanded ? 'button-menu-mobile-expanded' : ''
          }`}
          onClick={() => toggleNavbar()}
        >
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </div>
      <div
        ref={ref}
        className={`h-auto routes-container-mobile align-items-start justidy-content-center m gap-3 d-flex flex-column d-lg-none ${
          expanded ? 'navbar-expanded' : 'navbar-not-expanded'
        }`}
      >
        <Accordion defaultActiveKey={`0`}>
          {navbarRoutes.map((route: INavbarRoute, index: number) => {
            return (
              <Accordion.Item eventKey={`${index}`} key={index}>
                <Accordion.Header className="font-pnsb">
                  <div
                    className={`navbar-route-mobile w-100 ${
                      route.submenu
                        ? route.route.includes(location.pathname)
                          ? 'active-route'
                          : ''
                        : route.route[0] === location.pathname
                        ? 'active-route'
                        : ''
                    }`}
                    onClick={(e) => {
                      !route.submenu && handleNavigation(e, route);
                    }}
                  >
                    {route.title}
                  </div>{' '}
                  {route.submenu && <Plus className="button-transition" />}
                </Accordion.Header>
                {route.submenu ? (
                  <Accordion.Body>
                    {route?.submenuItems?.map((item, index2) => {
                      return (
                        <div
                          key={index2}
                          className={`navbar-route-mobile ${
                            item.route[0] === location.pathname
                              ? 'active-route'
                              : ''
                          }`}
                          onClick={(e) =>
                            handleNavigation(e, item as unknown as INavbarRoute)
                          }
                        >
                          {item.title}
                        </div>
                      );
                    })}
                  </Accordion.Body>
                ) : null}
              </Accordion.Item>
            );
          })}
        </Accordion>
        {/* <div className="d-block d-lg-none">
                        <Button
                            title={"Get a quote"}
                            height={48}
                            heightResponsive={32}
                            color={"#FFFFFF"}
                            hoverColor={"#e3d6a2"}
                            outlineColor={"transparent"}
                            bgColor={"#0B482C"}
                            customClass="navbar-btn"
                        />

                    </div> */}
      </div>
    </nav>
  );
};

export default Navbar;
