import { memo } from 'react';

export interface ICalendarProps {
  active: boolean;
  event: {
    name: string;
    type: string;
    image: string;
  };
  circuit: {
    name: string;
    length: string;
    trackWidth: string;
    leftCorners: string;
    rightCorners: string;
    longestStraight: string;
  };
  country: {
    name: string;
    flag: string;
  };
  date: {
    day: number;
    month: string;
    fullDate: string;
  };
}

const CalendarItem = ({ ...data }: ICalendarProps) => {
  return (
    <div className="calendar-item slide h-100 w-100 d-flex flex-column text-center">
      <div className="d-flex flex-column lh-1 h-100 justify-content-center">
        {data.active ? (
          <img
            src={data.event.image}
            className="mb-3"
            alt=""
            style={{ maxHeight: '150px' }}
          />
        ) : (
          <>
            <span className="calendar-day font-ri-sb text-light font-size-64">
              {data.date.day}
            </span>
            <span className="calendar-month font-size-32 font-ti-sb gray-color mb-3">
              {data.date.month}
            </span>
          </>
        )}

        <span className="calendar-country font-size-40 mb-3">
          {data.country.flag}
        </span>
        <span className="text-light font-size-24 font-ri-sb">
          {data.event.name}
        </span>

        {data.active ? (
          <>
            <span className="calendar-day font-ri-l text-light font-size-24 mt-3">
              {data.date.fullDate}
            </span>
            <span className="calendar-day font-ti-r text-light font-size-20 mt-3">
              {data.circuit.name}
            </span>
          </>
        ) : null}
      </div>
      <div className="right-border"></div>
    </div>
  );
};
export default memo(CalendarItem);
