import React, { useEffect, useRef, useState } from 'react';

import Slider from 'react-slick';
import './Hero.scss';
import Title from '../Title/Title';
import Uppertitle from '../Uppertitle/Uppertitle';
import Button from '../Button/Button';
import TrackBanner from '../TrackBanner/TrackBanner';
import axios from 'axios';
import { toast } from 'react-toastify';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  draggable: false,
  autoplaySpeed: 10200,
  slidesToShow: 1,
  pauseOnHover: false
};

interface IHero {
  bg: string;
  title: JSX.Element;
  callback: () => void;
}

interface IHeroProps {
  props: IHero[];
  banner?: boolean;
}

const Hero: React.FC<IHeroProps> = (props): JSX.Element => {
  const sliderRef = useRef<any>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [triggerAnim, setTriggerAnim] = useState<boolean>(false);
  const [currentTitle, setCurrentTitle] = useState<JSX.Element>(
    props.props[0].title
  );
  const [sliderData, setSliderData] = useState(props.props);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_RNF_SERVER + '/api/blog/allBlogs'
        );

        if (response.status === 200) {
          let first3Blogs = response.data.slice(0, 3);

          let serverSliderData = [];

          for (let blog of first3Blogs) {
            let title = blog['title'];
            if (title.length > 40) {
              title = title.substring(0, 35) + '...';
            }

            serverSliderData.push({
              bg: process.env.REACT_APP_RNF_SERVER + blog['background']['url'],
              title: <>{title}</>,
              callback: () => window.location.replace('/blog/' + blog['slug'])
            });
          }

          setSliderData(serverSliderData);
          setCurrentTitle(serverSliderData[0]['title']);
          setIsLoading(false);
        }
      } catch (err) {
        // setSliderData(props.props);
        toast.info('Error in loading hero slider data', {
          toastId: 'error-hero-slider'
        });
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setTriggerAnim(true);
    const timeout = setTimeout(() => {
      setCurrentTitle(sliderData[currentIndex].title);
    }, 350);
    const timeout2 = setTimeout(() => {
      setTriggerAnim(false);
    }, 600);

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout2);
    };
  }, [currentIndex, sliderData]);

  if (isLoading) {
    return (
      <>
        <div style={{ height: '20px' }}></div>
      </>
    );
  }

  return (
    <>
      <section id="hero" className="position-relative">
        <div className="img-slider-container w-100 h-100 d-flex">
          <div className="overlay-background"></div>
          <Slider
            {...settings}
            ref={sliderRef}
            afterChange={(next: number) => setCurrentIndex(next)}
            beforeChange={(current: number, next: number) =>
              setCurrentIndex(next)
            }
          >
            {sliderData.map((el, index) => {
              return (
                <img
                  key={'slick-' + index}
                  src={el.bg}
                  loading="lazy"
                  alt="bg"
                  className="bg-img"
                />
              );
            })}
          </Slider>
        </div>
        <div className="container-main py-3 py-md-4 d-flex align-items-start gap-3 gap-md-5 justify-content-start flex-column">
          <Uppertitle uppertitle="NEWS" background="#031B3F" color="#0BD885" />

          <div className={`z-2 ${triggerAnim ? 'anim-title' : ''}`}>
            <Title title={currentTitle} color="#FFFFFF" size={80} />
          </div>

          <Button
            title="Read Story"
            height={48}
            heightResponsive={48}
            heightResponsive580={36}
            color="#000000"
            outlineColor="transparent"
            bgColor="#0BD885"
            hoverColor="#000000"
            bgHoverColor="#08FF9B"
            customClass="z-2 radius8 font-ri-m font-size-20"
            callback={(e) => sliderData[currentIndex].callback()}
            // callback={test}
          />

          <div
            className={`w-100 mt-3 pb-5 d-flex align-items-start align-items-md-end justify-content-start gap-3 ${
              props?.banner ? 'mb-banner' : ''
            }`}
          >
            {sliderData.map((el, index) => {
              return (
                <div
                  key={index}
                  onClick={() => sliderRef?.current?.slickGoTo(index)}
                  className="ms-2 ms-md-0 cursor-pointer progress-container position-relative d-flex aligm-items-start justify-content-start gap-2 flex-column"
                >
                  <div
                    className={`progress-div ${
                      index > currentIndex
                        ? 'future-progress'
                        : index < currentIndex
                        ? 'past-progress'
                        : 'current-progress'
                    }`}
                  ></div>
                  <span
                    className={`element-title font-size-18 font-ti-r z-2 lh-12 ${
                      index > currentIndex ? 'gray-color' : 'white-color'
                    }`}
                  >
                    {el.title.props.children.slice(0, 33) + '...'}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {props?.banner ? <TrackBanner /> : null}
    </>
  );
};

export default Hero;
